import { ApolloClient, InMemoryCache, from } from '@apollo/client'
import { ON_SERVER } from 'shared/enums/environment'
import criticalEnv from 'helpers/criticalEnv'
import { errorLink, httpLink, defaultOptions } from 'shared/helpers/apollo'

// Get the correct URI depending on SSR / frontend, and Docker / production.
export const getApolloURI = () => {
  // This ENV var should always be set, it's the publicly accessible Hasura URL
  const publicInternetURI = criticalEnv('NEXT_PUBLIC_HASURA_GRAPHQL_ENDPOINT')

  // This ENV var only needs to be set locally for docker compose usage
  const dockerInternalURI =
    process.env.HASURA_GRAPHQL_DOCKER_ENDPOINT || publicInternetURI

  // Server side in docker compose we need to use the internal docker network
  return ON_SERVER ? dockerInternalURI : publicInternetURI
}

// We use this same client export FE (useQuery) and BE (getStaticProps)
// but they need to behave quite differently so be careful.
export const client = new ApolloClient({
  uri: getApolloURI(),
  link: from([errorLink, httpLink()]),
  cache: new InMemoryCache(),
  ssrMode: ON_SERVER,
  defaultOptions
})
