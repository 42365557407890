import { Breadcrumb, BreadcrumbHint } from '@sentry/nextjs'

const configureSentryBreadcrumb = (
  breadcrumb: Breadcrumb,
  hint: BreadcrumbHint
) => {
  if (breadcrumb.category === 'ui.click') {
    const { target } = hint.event
    if (target.ariaLabel) {
      breadcrumb.message = target.ariaLabel
    } else if (target.title) {
      breadcrumb.message = target.title
    } else if (target.innerText) {
      breadcrumb.message = target.innerText
    }
  } else if (breadcrumb.category === 'fetch') {
    if (breadcrumb?.data?.url.includes('/v1/graphql')) {
      if (hint?.input[1]?.body) {
        breadcrumb.data = {
          query: JSON.parse(hint?.input[1]?.body).operationName
        }
      }
    }
  }

  return breadcrumb
}

export default configureSentryBreadcrumb
