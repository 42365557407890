import { RecursivePartial, Constructor } from 'shared/presenters/presenter'
import { Translations } from 'shared/presenters/graphqlTypes'
import { locale } from 'shared/helpers/setJSLocale'

/**
 * Type of a presenter that can be translated.
 */
export type Translatable = Constructor<{
  translations?: RecursivePartial<Translations>[]
}>

/**
 * This mixin can add a translateField method to any presenter
 * that has a translations property.
 * You can use the method directly or define shorthand methods like so:
 *
 *   name = () => this.translateField('name')
 */
export function translatablePresenter<T extends Translatable>(Base: T) {
  return class TranslatableClass extends Base {
    /**
     * Translate a field based on the current locale
     * Takes a translations array and returns the value of a specific field
     * in the user's current locale.
     * @property field - The field to translate
     * @returns string translated value the field
     */
    translateField = (field: Translations['field']) => {
      if (!this.translations) return ''

      const fieldTranslations = this.translations.filter(t => t.field === field)

      const translationInDesiredLanguage = fieldTranslations.find(
        t => t.language === locale
      )?.value_scrubbed

      if (translationInDesiredLanguage) return translationInDesiredLanguage

      return (
        fieldTranslations.find(t => t.language === 'en')?.value_scrubbed ?? ''
      )
    }
  }
}
