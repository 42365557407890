import { present } from 'shared/presenters/presenter'
import { Store_Items } from 'shared/presenters/graphqlTypes'
import { translatablePresenter } from 'shared/presenters/translatablePresenter'
import { DEFAULT_FALLBACK_IMAGE } from 'enums/image'

export class StoreItemPresenter extends translatablePresenter(
  present<Store_Items>()
) {
  name = () => this.translateField('name')

  imageURL = () => {
    return DEFAULT_FALLBACK_IMAGE
  }
}
