import { ON_SERVER } from 'shared/enums/environment'

export interface LocalStorageHelpers {
  /**
   * Verify if localStorage is available (to prevent issues with browsers/extensions blocking it)
   */
  isAvailable: () => boolean
  /**
   * Retrieves localStorage value for key, and asserts to type T
   */
  getItem: <T>(key: string | undefined) => T | undefined
  /**
   * Stores value against localStorage key
   */
  setItem: <T>(key: string | undefined, value: T) => T | undefined
}

/**
 * Verify if localStorage is available (to prevent issues with browsers/extensions blocking it)
 */
export const isLocalStorageAvailable = () => {
  try {
    if (typeof localStorage !== 'undefined' && !ON_SERVER) return true
    else return false
  } catch (e) {
    return false
  }
}

/**
 * Retrieves localStorage value for key, and asserts to type T
 * Not type safe, see https://stackoverflow.com/a/62438143/17074522
 */
export const getLocalStorageValue = <T>(key: string | undefined) => {
  if (!key || ON_SERVER || !isLocalStorageAvailable()) return

  const rawStoredString = localStorage.getItem(key)

  if (!rawStoredString) return

  try {
    const parsedJSON: T = JSON.parse(rawStoredString)
    return parsedJSON
  } catch (e) {
    return
  }
}

/**
 * Stores value against localStorage key
 */
export const setLocalStorageValue = <T>(key: string | undefined, value: T) => {
  if (!key || ON_SERVER || !isLocalStorageAvailable()) return
  try {
    localStorage.setItem(key, JSON.stringify(value))
  } catch (e) {
    return
  }
  return value
}
