import { PREFIX_IMAGE_PATH } from 'shared/enums/image'

export const DEFAULT_FALLBACK_IMAGE_NAME = 'fallback-image-pattern'
export const DEFAULT_FALLBACK_IMAGE = `${PREFIX_IMAGE_PATH}/f_auto,q_auto:eco/v1631026341/eola/public_images/${DEFAULT_FALLBACK_IMAGE_NAME}.png`
export const DEFAULT_FALLBACK_IMAGE_THUMBNAIL = `${PREFIX_IMAGE_PATH}/t_base/t_thumb/c_crop,h_80,w_80/v1631026341/eola/public_images/${DEFAULT_FALLBACK_IMAGE_NAME}.png`

// Use this variable for mocking a dummy image on tests and style guide pages
export const DUMMY_IMAGE = `${PREFIX_IMAGE_PATH}/f_auto,q_auto:best/c_fill,h_630,w_auto/v1608728017/eola/public_images/surf-wdiget-cover.jpg`

export const DUMMY_NEXT_IMAGE =
  'https://res.cloudinary.com/eola-localhost/image/upload/f_auto,q_auto:best/c_fill,h_630,w_auto/v1608728017/eola/public_images/surf-wdiget-cover.jpg'
