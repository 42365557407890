import { defined, present, mapPresenter } from 'shared/presenters/presenter'
import { translatablePresenter } from 'shared/presenters/translatablePresenter'
import { SchedulablePresenter } from 'shared/presenters/SchedulablePresenter'
import { Class_Passes, Schedulables } from 'shared/presenters/graphqlTypes'

export class ClassPassPresenter extends translatablePresenter(
  present<Class_Passes>()
) {
  name = () => this.translateField('name')
  tagline = () => this.translateField('tagline')
  description = () => this.translateField('description')

  classPassActivities = () => {
    const classPassActivities = defined(this.class_passes_activities)

    return mapPresenter(
      SchedulablePresenter,
      classPassActivities.map(
        activity => activity.schedulable
      ) as unknown as Schedulables[]
    )
  }
}
