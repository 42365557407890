import { useEffect, DependencyList, RefObject, useState } from 'react'
import { determineIsNotNullish } from 'shared/helpers/utilityTypes'

export interface UseOnClickOutsideProps {
  /**
   * An array of element refs, that clicking outside of will trigger the click away.
   */
  refs: RefObject<HTMLElement>[]
  onClickOutside: () => void
  /**
   * There are cases when onClickOutside might have a value that belongs to a provider,
   * which means on the first load this parameter will be a default value that gets
   * updated with a real value once the provider has finished loading. This dependency
   * list will inform the hook to be updated.
   */
  dependencyList?: DependencyList
  /**
   * @note You only need it if you can't provide all the necessary refs.
   *
   * The best way to use useOnClickOutside is by providing as many ref as possible, we suggest
   * a ref for the content that will be watched when the click outside happens but also another
   * reft for the button that shows this content.
   *
   * We need this second ref because the addEventListener in this hook is used for the whole document,
   * which means it is also registering the click when the user clicks to show the hidden element,
   * otherwise, if there isn't this second ref the click on the button would hide this element straight
   * after showing it.
   *
   * In other words, the ref should be given to the hidden component but also the button that shows it.
   *
   * There are cases when it is not possible to pass the ref for the button because of prop drilling,
   * for this case we can use useBooleanToDetermineWhenIsReadyToWatchClicks, which would prevent the
   * button from triggering the clickOutside.
   */
  useBooleanToDetermineWhenIsReadyToWatchClicks?: boolean
}

/**
 * Detect a click outside a component.
 */
const useOnClickOutside = ({
  refs,
  onClickOutside,
  dependencyList = [],
  useBooleanToDetermineWhenIsReadyToWatchClicks
}: UseOnClickOutsideProps) => {
  const [readyForClickOutside, setReadyForClickOutside] = useState(
    !useBooleanToDetermineWhenIsReadyToWatchClicks
  )

  useEffect(() => {
    if (useBooleanToDetermineWhenIsReadyToWatchClicks === undefined) return
    setReadyForClickOutside(!readyForClickOutside)
    // TODO #6362 - Fix ESLint - hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useBooleanToDetermineWhenIsReadyToWatchClicks])

  useEffect(() => {
    if (!readyForClickOutside || !onClickOutside) return

    document.addEventListener('click', handleClick)
    return () => document.removeEventListener('click', handleClick)
    // TODO #6362 - Fix ESLint - hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencyList, readyForClickOutside])

  const handleClick = ({ target }: MouseEvent) => {
    const references = refs
      .map(ref => ref.current)
      .filter(determineIsNotNullish)

    if (
      target instanceof Element &&
      !references.some(ref => ref.contains(target))
    ) {
      onClickOutside()
    }
  }
}

export default useOnClickOutside
