import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { Projects } from 'shared/enums/projects'
// This necessary evil imports one variable that is null unless in test mode
import { hostname } from 'shared/test/helpers/mockHostname'

export type Project = keyof typeof Projects

/**
 * Use this hook to identify if the file you are working belongs to business or consumer.
 * If it is consumer it will then also determine if belongs the widget or the eola (public facing).
 */
const useDetermineProject = (): Project | undefined => {
  const { query } = useRouter()
  const widgetSlug = query?.widgetSlug
  const [project, setProject] = useState<Project | undefined>(undefined)

  /**
   * In order to prevent the first render from being different we can use `useEffect` which is only
   * executed in the browser and is executed during hydration.
   */
  useEffect(() => {
    // JSDOM blocks mocking window location for philosophical reasons,
    // so in test mode we have to use a separate variable that can be mocked.
    const host = hostname || window.location.hostname
    if (host.includes(Projects.business)) setProject(Projects.business)
    else setProject(widgetSlug ? Projects.widget : Projects.eola)
  }, [widgetSlug])

  return project
}

export default useDetermineProject
