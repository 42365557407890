export enum ChargeableType {
  Booking = 'Booking'
}

export const VouchersTypeEnum = {
  ActivityVoucher: 'ActivityVoucher',
  CreditVoucher: 'CreditVoucher',
  ClassPasses: 'ClassPasses',
  PurchasedClassPass: 'PurchasedClassPass'
}
