import { present, definedNotNull } from 'shared/presenters/presenter'
import { Time_Slot_Bookings } from 'shared/presenters/graphqlTypes'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import dayjs from 'dayjs'

dayjs.extend(localizedFormat)
dayjs.extend(advancedFormat)

const TIME_FORMAT = 'kk:mm'
const DATE_FORMAT_SHORT_DAY = 'ddd D MMM'
const DATE_FORMAT_LONG_DAY = 'dddd Do MMM'

export class TimeSlotBookingPresenter extends present<Time_Slot_Bookings>() {
  timeStartToEnd = () => {
    return `${dayjs(definedNotNull(this.start)).format(TIME_FORMAT)} -
                ${dayjs(definedNotNull(this.finish)).format(TIME_FORMAT)}`
  }
  dateStartToEnd = () => {
    return `${dayjs(definedNotNull(this.start)).format(DATE_FORMAT_SHORT_DAY)} -
                ${dayjs(definedNotNull(this.finish)).format(
                  DATE_FORMAT_SHORT_DAY
                )}`
  }
  dateText = () => {
    return dayjs(definedNotNull(this.start)).format(DATE_FORMAT_LONG_DAY)
  }
}
