import { determineNodeEnvironment } from 'shared/helpers/determineEnvironment'

/**
 * Checks to see if running as a part of the test environment.
 *
 * We want to reduce animaton times in tests to reduce test run time, intermittent failures and the need for devs to remember
 * to write code to wait for animations in e2e tests.
 */
const reduceTimeForTest = (duration: number) => {
  if (determineNodeEnvironment().isTest) {
    return 0
  }
  return duration
}

export default reduceTimeForTest
