import React from 'react'
import styled from '@emotion/styled'
import useTranslation from 'next-translate/useTranslation'

interface SpinnerProps {
  centered?: boolean
}

const StyledWrapper = styled.div<SpinnerProps>(({ theme, centered }) =>
  // change style if centered
  centered
    ? {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        marginTop: theme.space[1],
        marginBottom: theme.space[1]
      }
    : undefined
)

const Spinner = ({ centered = false, ...otherProps }: SpinnerProps) => {
  const { t } = useTranslation('common')

  return (
    <StyledWrapper centered={centered} {...otherProps}>
      <StyledSpinner role="alert" aria-label={t('loading')} />
    </StyledWrapper>
  )
}

export default Spinner

const StyledSpinner = styled.span(() => ({
  display: 'inline-block',
  width: '1em',
  height: '1em',
  borderRadius: '50%',
  verticalAlign: 'top',
  border: '1px solid currentColor',
  borderRightColor: 'transparent',
  animation: 'spin 1s infinite linear',
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)'
    },

    '100%': {
      transform: 'rotate(360deg)'
    }
  }
}))
