import { defined, present } from 'shared/presenters/presenter'
import { translatablePresenter } from 'shared/presenters/translatablePresenter'
import { Membership_Prices } from 'shared/presenters/graphqlTypes'

export class MembershipPricePresenter extends translatablePresenter(
  present<Membership_Prices>()
) {
  name = () => this.translateField('nickname')

  totalCost = defined(this?.unit_amount) + defined(this?.sign_up_fee)
}
