import { present, defined } from 'shared/presenters/presenter'
import { Answer_Sets } from 'shared/presenters/graphqlTypes'

export class AnswerSetsPresenter extends present<Answer_Sets>() {
  for_user = () => {
    if (defined(this.child)) {
      return defined(this.child)?.name
    }
    return defined(this?.enthusiast)?.name
  }
}
