export const coreTypography = {
  fonts: {
    /**
     * Remove space in between values to prevent errors when testing
     * bad: ', '
     * good: ','
     */
    body: `'Chivo','Open Sans','Helvetica Neue Light','Helvetica Neue','Helvetica','Arial',sans-serif`,
    heading: `'Poppins','Circular','Open Sans','Helvetica Neue Light','Helvetica Neue','Helvetica','Arial',sans-serif`
  },
  fontSizes: {
    xxxl: 46,
    xxl: 24,
    xl: 22,
    lg: 18,
    md: 16,
    sm: 14,
    xs: 12
  },
  fontWeights: {
    body: 400,
    heading: 600
  },
  letterSpacings: [0],
  lineHeights: {
    xxxl: 64,
    xxl: 38,
    xl: 36,
    lg: 28,
    md: 24,
    sm: 22,
    xs: 20
  }
}

export const fontStyle = {
  h1: {
    fontFamily: coreTypography.fonts.heading,
    fontWeight: coreTypography.fontWeights.heading,
    fontSize: coreTypography.fontSizes.xxxl,
    lineHeight: coreTypography.lineHeights.xxxl + 'px'
  },
  h2: {
    fontFamily: coreTypography.fonts.heading,
    fontWeight: coreTypography.fontWeights.heading,
    fontSize: coreTypography.fontSizes.xxl,
    lineHeight: coreTypography.lineHeights.xxl + 'px'
  },
  h3: {
    fontFamily: coreTypography.fonts.heading,
    fontWeight: coreTypography.fontWeights.heading,
    fontSize: coreTypography.fontSizes.xl,
    lineHeight: coreTypography.lineHeights.xl + 'px'
  },
  h4: {
    fontFamily: coreTypography.fonts.heading,
    fontWeight: coreTypography.fontWeights.heading,
    fontSize: coreTypography.fontSizes.lg,
    lineHeight: coreTypography.lineHeights.lg + 'px'
  },
  h5: {
    fontFamily: coreTypography.fonts.heading,
    fontWeight: coreTypography.fontWeights.heading,
    fontSize: coreTypography.fontSizes.md,
    lineHeight: coreTypography.lineHeights.md + 'px'
  },
  h6: {
    fontFamily: coreTypography.fonts.heading,
    fontWeight: coreTypography.fontWeights.heading,
    fontSize: coreTypography.fontSizes.sm,
    lineHeight: coreTypography.lineHeights.sm + 'px'
  },
  p1: {
    fontFamily: coreTypography.fonts.body,
    fontWeight: coreTypography.fontWeights.body,
    fontSize: coreTypography.fontSizes.lg,
    lineHeight: coreTypography.lineHeights.lg + 'px'
  },
  p2: {
    fontFamily: coreTypography.fonts.body,
    fontWeight: coreTypography.fontWeights.body,
    fontSize: coreTypography.fontSizes.md,
    lineHeight: coreTypography.lineHeights.md + 'px'
  },
  p3: {
    fontFamily: coreTypography.fonts.body,
    fontWeight: coreTypography.fontWeights.body,
    fontSize: coreTypography.fontSizes.sm,
    lineHeight: coreTypography.lineHeights.sm + 'px'
  },
  p4: {
    fontFamily: coreTypography.fonts.body,
    fontWeight: coreTypography.fontWeights.body,
    fontSize: coreTypography.fontSizes.xs,
    lineHeight: coreTypography.lineHeights.xs + 'px'
  }
}
