import React, { ReactNode, useState, useEffect, createContext } from 'react'
import { gql, useQuery } from '@apollo/client'
import { Query_Root } from 'shared/presenters/graphqlTypes'
import { EnthusiastPresenter } from 'shared/presenters'
import { useRouter } from 'next/router'
import { HttpHeader, XForceRole } from 'shared/enums/httpHeader'

export interface EnthusiastAuthContextProps {
  authenticated: boolean
  setAuthenticated: (authenticated: boolean) => void
  authLoading: boolean
  enthusiast?: EnthusiastPresenter
}

export const EnthusiastAuthContext = createContext<EnthusiastAuthContextProps>({
  authenticated: false,
  setAuthenticated: () => {
    throw new Error('useUser must be used within EnthusiastAuthProvider')
  },
  authLoading: true
})

/**
 * It creates a context for the App to check if the user is current signed in.
 *
 * Note:
 * It only covers enthusiast users. If the user is authenticated, it will also return the user's name
 * to avoid extra Hasura calls while using the useEnthusiast hook. This logic might change in the future
 * if it is necessary to cover other kinds of users or need to fetch some complex data.
 */
const EnthusiastAuthProvider = ({ children }: { children: ReactNode }) => {
  const { route, locale } = useRouter()
  const [authenticated, setAuthenticatedState] = useState<boolean>(false)

  /**
   *  authLoading
   *
   *  We are handling loading by ourselves instead of using useQuery because there is a delay between
   *  the useQuery loading state which changes the value to false but the enthusiast data isn't ready yet.
   *  This happens because the API call is creating the cookie and it takes some time to finish.
   *  Getting loading false from useQuery doesn't mean the enthusiast data is available since
   *  the cookie hasn't been fulled added to the browser yet.
   */

  const [authLoading, setAuthLoading] = useState<boolean>(true)

  const [enthusiast, setEnthusiast] =
    useState<EnthusiastAuthContextProps['enthusiast']>(undefined)

  const { refetch } = useQuery<Pick<Query_Root, 'Consumer_Sessions_Current'>>(
    FETCH_ENTHUSIASTS,
    {
      variables: {
        locale
      },
      onCompleted(response) {
        const enthusiast = response?.Consumer_Sessions_Current?.enthusiast

        if (enthusiast) {
          setEnthusiast(new EnthusiastPresenter(enthusiast))
          setAuthenticatedState(true)
        }
        setAuthLoading(false)
      },
      onError: () => {
        setAuthLoading(false)
      },
      context: { headers: { [HttpHeader.xForceRole]: XForceRole.enthusiast } }
    }
  )

  const setAuthenticated = (authenticated: boolean) => {
    setAuthenticatedState(authenticated)
    if (authenticated) {
      setAuthLoading(true)
      refetch()
    } else {
      setEnthusiast(undefined)
    }
  }

  // We need to add a watch for route,  otherwise the authentication modals stop working when switching between pages
  useEffect(() => {
    refetch()
    // TODO #6362 - Fix ESLint - hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route])

  return (
    <EnthusiastAuthContext.Provider
      value={{
        authenticated,
        setAuthenticated,
        authLoading,
        enthusiast
      }}
    >
      {children}
    </EnthusiastAuthContext.Provider>
  )
}

export default EnthusiastAuthProvider

export const FETCH_ENTHUSIASTS = gql`
  query WidgetEnthusiastAuthProviderFetchEnthusiasts($locale: String!) {
    Consumer_Sessions_Current {
      enthusiast {
        id
        name
        email
        contact_number
        current_sign_in_at
        stripe_customer_accounts {
          membership_subscriptions(
            where: { status: { _in: ["active", "trialing"] } }
          ) {
            membership_price {
              membership_product {
                translations(
                  where: {
                    language: { _in: [$locale, "en"] }
                    field: { _in: ["name", "description"] }
                    model_type: { _eq: "Memberships::Product" }
                  }
                ) {
                  language
                  field
                  value_scrubbed
                }
                membership_definitions {
                  membership_benefit {
                    type
                    discounted_percent
                    discounted_amount_pence
                    membership_definitions {
                      schedulable_id
                    }
                  }
                }
                schedulable_membership_restrictions {
                  schedulable_id
                }
              }
            }
          }
        }
      }
    }
  }
`
