import dayjs from 'dayjs'
import { Translate } from 'next-translate'
import { Dispatch, SetStateAction } from 'react'

// setDayJSLocale lives outside useSetupLocale.ts for mocking purposes
// while testing useSetupLocale.ts
const setDayJSLocale = (
  localeToSet: string,
  t: Translate,
  setLoaded: Dispatch<SetStateAction<boolean>>
) => {
  if (localeToSet !== 'en') {
    const dayjsLocale: ILocale | string = t(
      'locale',
      {},
      { returnObjects: true }
    )

    //   In the rare case there isn't a DayJS locale it will return `dayjs:locale`
    //   English is built in so we don't want to overwrite it (stuff breaks)
    if (dayjsLocale !== 'dayjs:locale' && typeof dayjsLocale === 'object') {
      // Put back the ordinal function DayJS requires with our approximation
      const ordinalCharacter = dayjsLocale.ordinal
      // TODO - Improve type
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const ordinalFunc = (n: any) =>
        ordinalCharacter ? `${n}${ordinalCharacter}` : n
      const modifiedDayjsLocale = { ...dayjsLocale, ordinal: ordinalFunc }
      // This is how DayJS registers locales in the locale files
      dayjs.locale(modifiedDayjsLocale, undefined, true)
      // This switches to that locale as the default
    }
  }
  dayjs.locale(localeToSet)

  // Causes the page to re-render and use the updated DayJS
  setLoaded(true)
}

export default setDayJSLocale
