import determineAccess, { AccessAction } from 'helpers/determineAccess'
import styled from '@emotion/styled'
import Spinner from 'library/atoms/Spinner'
import { ReactNode } from 'react'

/**
 * This is a placeholder for future work
 *
 * This is to ecnourage deelopers to use the same pattern seen in the business project
 */
const ShouldAccess = ({ children }: { children: ReactNode }) => {
  /**
   * When needing to add additional access rules please see the business project for how we
   * might want to feed values in here from the AuthProvider
   */
  const access = determineAccess({
    authLoading: false
  })

  if (access?.action === AccessAction.indicateLoading) {
    return (
      <StyledPageWrapper>
        <Spinner />
      </StyledPageWrapper>
    )
  }

  return <>{children}</>
}

export default ShouldAccess

const StyledPageWrapper = styled.div(() => ({
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center'
}))
