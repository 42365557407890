import ExpiredStorage from 'expired-storage'
import {
  LocalStorageHelpers,
  isLocalStorageAvailable
} from 'shared/helpers/localStorageHelpers'

export interface StorageApiInterface
  extends ExpiredStorage,
    Pick<LocalStorageHelpers, 'isAvailable'> {
  setBasket: (
    a: { widgetSlug: string; basketId: string },
    expiry?: number
  ) => void
  removeBasket: (a: string) => void
  getBasketId: (a: string) => StorageApiGetBasketId
  setMarketplaceReferral: (
    a: { widgetSlug: string; marketplace: string },
    expiry?: number
  ) => void
  getMarketplaceReferral: (a: { widgetSlug: string }) => StorageApiGetBasketId
}

export type StorageApiGetBasketId = string | null

const basketExpiryTimeInHours = 72
const marketplaceReferalExpiryTimeInHours = 3

export const BASKET_EXPIRY_TIME = basketExpiryTimeInHours * 60 * 60
export const MARKETPLACE_EXPIRY_TIME =
  marketplaceReferalExpiryTimeInHours * 60 * 60

/**
 * Declaring and exporting in a module since it means this will be used as a singleton
 * see https://github.com/RonenNess/ExpiredStorage/
 */
let expiredStorage = {} as StorageApiInterface

/**
 * Gets around next js static build errors
 * isLocalStorageAvailable checks this is not running server-side
 */
if (isLocalStorageAvailable()) {
  expiredStorage = new ExpiredStorage() as StorageApiInterface

  expiredStorage.setBasket = (
    { widgetSlug, basketId }: { widgetSlug: string; basketId: string },
    expiry: number = BASKET_EXPIRY_TIME
  ) => {
    expiredStorage.setItem(generateBasketKey(widgetSlug), basketId, expiry)
  }

  expiredStorage.removeBasket = (widgetSlug: string) => {
    expiredStorage.removeItem(generateBasketKey(widgetSlug))
  }

  expiredStorage.getBasketId = (widgetSlug: string) =>
    expiredStorage.getItem(generateBasketKey(widgetSlug))

  expiredStorage.setMarketplaceReferral = (
    { widgetSlug, marketplace }: { widgetSlug: string; marketplace: string },
    expiry: number = MARKETPLACE_EXPIRY_TIME
  ) =>
    expiredStorage.setItem(
      generateMarketplaceReferalKey(widgetSlug),
      marketplace,
      expiry
    )

  expiredStorage.getMarketplaceReferral = ({
    widgetSlug
  }: {
    widgetSlug: string
  }) => expiredStorage.getItem(generateMarketplaceReferalKey(widgetSlug))
}

const storageApi = {
  ...expiredStorage,
  isAvailable: isLocalStorageAvailable
} as StorageApiInterface

export default storageApi

const generateBasketKey = (widgetSlug: string) => `${widgetSlug}-basket`
const generateMarketplaceReferalKey = (widgetSlug: string) =>
  `${widgetSlug}-marketplace-referal`
