import { present } from 'shared/presenters/presenter'
import { Line_Items } from 'shared/presenters/graphqlTypes'

export class LineItemPresenter extends present<Line_Items>() {
  getDisclaimer = () => {
    const disclaimers = this.booking
      ? this?.booking?.party?.disclaimers_parties?.map(dp => dp.disclaimer)
      : this?.membership_subscription?.disclaimers_membership_subscriptions?.map(
          dms => dms.disclaimer
        )
    // Find the first present disclaimer you have access to
    // (there may be null disclaimers for other participants)
    return disclaimers?.find(disclaimer => disclaimer?.id)
  }

  getDisclaimers = () => {
    return this.booking?.party?.disclaimers_parties
      ? this.booking.party.disclaimers_parties.flatMap(
          dp => dp.disclaimer ?? []
        )
      : this.membership_subscription?.disclaimers_membership_subscriptions
        ? this.membership_subscription.disclaimers_membership_subscriptions.flatMap(
            dms => dms.disclaimer ?? []
          )
        : []
  }

  getAnswerSets = () => {
    return this.booking?.answer_sets
      ? this.booking.answer_sets
      : this.membership_subscription?.answer_set
        ? [this.membership_subscription.answer_set]
        : []
  }
}
