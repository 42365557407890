import { Breakpoints } from 'assets/theme/theme'

const generateMediaQueries = (
  breakpoints: Breakpoints,
  property: 'min-width' | 'max-width' = 'min-width'
) => {
  const mediaQuery: { [k: string]: string } = {}

  Object.entries(breakpoints).forEach(
    ([key, value]) =>
      (mediaQuery[key] = `@media screen and (${property}: ${value}px)`)
  )
  return mediaQuery
}

export default generateMediaQueries
