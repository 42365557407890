import { useState, useEffect } from 'react'
import { ApolloError } from '@apollo/client'
import { InstructorPresenter, EnthusiastPresenter } from 'shared/presenters'
import { useRouter } from 'next/router'
import { Instructors, Maybe } from 'shared/presenters/graphqlTypes'

export type InstructorAuthState = {
  authenticated: boolean
  setAuthenticated: (authenticated: boolean) => void
  authLoading: boolean
  user?: InstructorPresenter | EnthusiastPresenter
  error?: ApolloError
}

const useAuthState = (
  userData: Maybe<Instructors> | undefined,
  instructorDataLoading: boolean,
  refetch: () => void,
  error: ApolloError | undefined
): InstructorAuthState => {
  const { route } = useRouter()
  const [authenticated, setAuthenticatedState] = useState<boolean>(!!userData)
  const [user, setUser] = useState<InstructorPresenter | undefined>(undefined)
  const [isLoading, setLoading] = useState<boolean>(!userData)

  /**
   * We fetch on every page change as a means of keeping up to date on the authentication status.
   * This covers a scenario where a user may sign out on other browser tabs
   */
  useEffect(() => {
    refetch()
    // TODO #6362 - Fix ESLint - hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route])

  useEffect(() => {
    if (instructorDataLoading) {
      return
    }

    if (error) {
      setLoading(false)
      setUser(undefined)
      setAuthenticatedState(false)
      return
    }
    if (!userData) {
      setUser(undefined)
      setAuthenticatedState(false)
      setLoading(false)
      return
    }
    if (isNewSignIn(userData, user)) {
      const newInstructor = userData
      const instructorPresented = newInstructor
        ? new InstructorPresenter(newInstructor)
        : undefined
      setUser(instructorPresented)
      setAuthenticatedState(true)
      setLoading(false)
      return
    }
  }, [userData, instructorDataLoading, error])

  /**
   * setAuthenticated is fired when a user has successfully signed in from another component
   * this then triggers the refetch which means the state is made up to date and reflecting the signed in status
   *
   * Note - potentially a todo here is rather than refetching here we could instead export the sign in / sign up functions
   * This would probably be easier to reason with
   */
  const setAuthenticated = (authenticated: boolean) => {
    setAuthenticatedState(authenticated)
    if (authenticated) {
      setLoading(true)
      refetch()
    } else {
      setUser(undefined)
      setAuthenticatedState(false)
    }
  }

  return {
    authenticated,
    setAuthenticated,
    authLoading: isLoading,
    user,
    error
  }
}

export default useAuthState

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isNewSignIn(instructorData: any, currentInstructor: any) {
  return (
    instructorData?.id &&
    (!currentInstructor || instructorData.id !== currentInstructor.id)
  )
}
