/**
 * GraphQL Types are automatically generated for us, but all fields are marked
 * as required even though you might not to query them.
 * So we need to make every field optional which TypeScript's Partial can do
 * e.g. Partial<Schedulable>
 * But that doesn't make fields of nested types optional.
 * So here we define RecursivePartial which does just that.
 * This way is commonly suggested, but doesn't work with arrays:
 * export type RecursivePartial<T> = { [P in keyof T]?: RecursivePartial<T[P]> }
 * This more complicated version handles arrays and properties.
 */
export type RecursivePartial<T> = {
  [K in keyof T]?: T[K] extends Array<infer R>
    ? Array<RecursivePartial<R>>
    : RecursivePartial<T[K]>
}

/**
 * Helper type to facilitate parameters accepting either RecursivePartial data or
 * data as standard (non presenter wrapped data or presenter wrapped data)
 */
export type MaybeRecursive<T> = T | RecursivePartial<T>

/**
 * Present a GraphQL Type's data by wrapping it with extra methods and fields.
 * Just give it a GraphQL type and it does the rest.
 *
 * export class ActivityPresenter extends present<Activity>() {}
 */
export function present<T>() {
  return class {
    constructor(data: RecursivePartial<T>) {
      Object.assign(this, data)
    }
  } as new (data: RecursivePartial<T>) => RecursivePartial<T>
}

/**
 * Use to make sure the field has been requested in the GraphQL query. Throws.
 * @param field GraphQL field that can be undefined
 */
export function defined<T>(field: T | undefined) {
  if (typeof field === 'undefined')
    throw new Error(
      `Undefined field from GraphQL\n ⮑ Check call stack for source! 👇 `
    )

  return field
}

/**
 * Use `defined` first then `notNull` to make sure the field is not null,
 * even though the database says it can be. Throws.
 * in the GraphQL query.
 * @param field field that can be null
 */
export function notNull<T>(field: T | null) {
  if (field === null)
    throw new Error(`Null field\n ⮑ Check call stack for source! 👇`)

  return field
}

/**
 * Use sparingly; prefer just `defined`.
 * For when you want to check a field is defined and think it should always be
 * not null even though the database says otherwise. Throws.
 * @param field field that can be undefined or null
 */
export function definedNotNull<T>(field: T | undefined | null) {
  return notNull(defined(field))
}

/**
 * Map a presenter to an array of objects.
 */
export function mapPresenter<P, I>(
  presenter: new (item: I) => P,
  array?: I[]
): P[] {
  return (array || []).map(item => new presenter(item))
}

/**
 * Type of a generic constructor used for creating presenter mixins.
 * Don't use directly, create a more specific type or intersection type.
 *
 * To create a more specific type, specify which properties a presenter must
 * have to be able to use your mixin.
 * E.g. you can only make presenters translatable that... have translations:
 *
 *   type Translatable = Constructor<{ translations?: RecursivePartial<Translations>[] }>
 *
 * More info on constrained mixins in TypeScript:
 * https://www.typescriptlang.org/docs/handbook/mixins.html#constrained-mixins
 *
 * Note the `any` type is _required_ by TypeScript for mixins;
 * it specifically detects `...args: any[]` to enable mixin behaviour.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Constructor<T> = new (...args: any[]) => T
