import {
  MembershipBenefitTranslateId,
  MEMBERSHIPS_DECRIPTIONS_IDS,
  MembershipBenefitType
} from 'shared/enums/memberships'
import { present } from 'shared/presenters/presenter'
import { translatablePresenter } from 'shared/presenters/translatablePresenter'
import { Membership_Products } from 'shared/presenters/graphqlTypes'
import { Translate } from 'next-translate'
import { locale } from 'shared/helpers/setJSLocale'

export class MembershipPresenter extends translatablePresenter(
  present<Membership_Products>()
) {
  name = () => this.translateField('name')
  description = () => this.translateField('description')

  /**
   * Benefits description is returned from a backend class method we don't have access
   * so we need to write a front end helper for it.
   *
   * There's 3 types of benefit, DiscountedPercented, DiscountedFixedAmount and NoCharge.
   * This method returns the description of the benefit to match the backend.
   *
   * It uses next-translate/useTranslation to also return the translated version.
   *
   * References:
   * - rails/app/models/memberships/benefits
   * - rails/app/models/memberships/product.rb
   */
  benefitsSummary = (t: Translate): string => {
    const benefitsListForTranslation = ():
      | Record<MembershipBenefitTranslateId, number>
      | undefined => {
      const definitionTypes: Record<
        MembershipBenefitTranslateId | string,
        number
      > = {}

      // Loop through definitions to populate object for translation
      this.membership_definitions?.forEach(definition => {
        const typeName = definition?.membership_benefit?.type?.split('::')[2]
        const typeTranslateId =
          MEMBERSHIPS_DECRIPTIONS_IDS[typeName as MembershipBenefitType]
            ?.translateId

        if (!typeTranslateId) {
          return
        }

        definitionTypes[typeTranslateId] =
          (definitionTypes?.[typeTranslateId] ?? 0) + 1
      })

      // Return undefined if object is empty
      if (
        this.membership_definitions?.length === 0 ||
        Object.keys(definitionTypes).length === 0
      ) {
        return
      }

      return definitionTypes
    }

    const translateMembershipBenefitsSummary = ({
      benefits,
      t
    }: {
      benefits?: Record<MembershipBenefitTranslateId, number>
      t: Translate
    }) => {
      if (!benefits) {
        return ''
      }

      const descriptionList = Object.entries(benefits)?.map(([key, value]) =>
        t('chargeable:' + key, { count: value })
      )

      const textSettings = new Intl.ListFormat(locale, {
        style: 'long',
        type: 'conjunction'
      })

      return textSettings.format(descriptionList) + '.'
    }

    return translateMembershipBenefitsSummary({
      benefits: benefitsListForTranslation(),
      t
    })
  }
}
