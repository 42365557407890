import { determineNodeEnvironment } from 'shared/helpers/determineEnvironment'

const { isTest } = determineNodeEnvironment()

const NEXT_PUBLIC_CLOUDINARY_ORG = process.env.NEXT_PUBLIC_CLOUDINARY_ORG

/**
 * The Business and Consumer projects need to use different CLOUDINARY_ORG for now because Nebula on development
 * uploads images with the Cloudinary ORG "eola-localhost" instead of "eola" so uploaded images are not saved
 * into the production data.
 *
 * The Consumer isn't ready to use "eola-localhost" because all the available images for dev belong to "eola".
 */
const cloudinaryOrg = isTest ? 'eola-localhost' : NEXT_PUBLIC_CLOUDINARY_ORG

// Base path for displaying images
export const PREFIX_IMAGE_PATH = `https://res.cloudinary.com/${cloudinaryOrg}/image/upload`
// Path for uploading images
export const UPLOAD_IMAGE_PATH = `https://api.cloudinary.com/v1_1/${NEXT_PUBLIC_CLOUDINARY_ORG}/auto/upload`

/**
 * Transformations that can be used in Cloudinary URLs to deliver
 * modified images.
 */
export enum Transformation {
  chargeable = 'f_auto/t_base/t_schedulable_default', // It has "schedulable" on the transformation but it is also used as cover image for all chargeables
  fullscreen = 'f_auto/t_base/t_fullscreen',
  profile = 'f_auto/t_base/t_square',
  thumb = 'f_auto/t_base/t_thumb',
  widgetLogo = 't_widget_logo_image'
}

export type TransformationKeys = keyof typeof Transformation
