import { rgba } from 'polished'

const opacity = [0.04, 0.1, 0.16]

const variants = [
  {
    blurRadius: '12px',
    offsetY: '16px'
  },
  {
    blurRadius: '34px',
    offsetY: '24px'
  },
  {
    blurRadius: '44px',
    offsetY: '34px'
  }
]

const generateShadows = (color: string) => ({
  0: `0 ${variants[0].offsetY} ${variants[0].blurRadius} ${rgba(
    color,
    opacity[0]
  )}`,
  1: `0 ${variants[1].offsetY} ${variants[1].blurRadius} ${rgba(
    color,
    opacity[1]
  )}`,
  2: `0 ${variants[2].offsetY} ${variants[2].blurRadius} ${rgba(
    color,
    opacity[2]
  )}`,
  top: {
    0: `0 -${variants[0].offsetY} ${variants[0].blurRadius} ${rgba(
      color,
      opacity[0]
    )}`,
    1: `0 -${variants[1].offsetY} ${variants[1].blurRadius} ${rgba(
      color,
      opacity[1]
    )}`,
    2: `0 -${variants[2].offsetY} ${variants[2].blurRadius} ${rgba(
      color,
      opacity[2]
    )}`
  }
})

export default generateShadows
