/* eslint-disable @typescript-eslint/no-explicit-any */
import { ON_SERVER } from 'shared/enums/environment'
import { determineDeploymentEnvironment } from 'shared/helpers/determineEnvironment'

// Type declaration for Google Tag Manager - They don't provide a package for their types at the moment.
declare global {
  interface Window {
    dataLayer: Record<string, unknown>[]
    gtag?: (
      key: string,
      command: string,
      config: { [key: string]: any }
    ) => void
    ga?: (key: string, command: string, config: { [key: string]: any }) => void
    fbq?: (key: string, command: string, config: { [key: string]: any }) => void
  }
}

/**
 * The page trackers should only be active on live production to prevent bad bounce rate.
 * This const value can be changed to false if you need to test something.
 */
export const pageTrackerIsAllowed =
  !ON_SERVER && determineDeploymentEnvironment().isLive

/**
 * Facebook provides more events than this, see docs for more information.
 * [ADD LINK TO THOSE DOES HERE PLEASE]
 */
type FbPixelTrackProps =
  | {
      event: 'AddToCart' | 'PageView'
      parameters?: never
    }
  | {
      event: 'Purchase'
      parameters?: facebook.Pixel.PurchaseParameters
    }

export const fbPixelTrack = ({ event, parameters }: FbPixelTrackProps) => {
  const hasFacebook = pageTrackerIsAllowed && typeof window.fbq !== 'undefined'
  if (!hasFacebook) return
  if (!parameters) return window.fbq('track', event)

  window.fbq('track', event, parameters)
}

const fbPixelTrackPageview = () => {
  const hasFacebook = pageTrackerIsAllowed && typeof window.fbq !== 'undefined'
  if (!hasFacebook) return
  fbPixelTrack({ event: 'PageView' })
}

const gaTrackPageview = (url: string, path: string) => {
  const hasGa =
    pageTrackerIsAllowed &&
    (typeof window.ga !== 'undefined' || typeof window.gtag !== 'undefined')
  if (!hasGa) return

  // GA4 Tracking
  if (typeof window.gtag !== 'undefined') {
    window.gtag('event', 'page_view_next', {
      page_path: url,
      next_page_path: path
    })
  }

  // GA3 Tracking
  if (typeof window.ga !== 'undefined') {
    window.ga('send', 'pageview', {
      page: url
    })
  }
}

const gaTrackerViewSwitch = (viewSwitchId: string, view: string) => {
  const hasGa =
    pageTrackerIsAllowed &&
    (typeof window.ga !== 'undefined' || typeof window.gtag !== 'undefined')
  if (!hasGa) return
  // GA4 Tracking
  if (typeof window.gtag !== 'undefined') {
    window.gtag('event', 'view_switch', {
      view_switch_id: viewSwitchId,
      to_view: view
    })
  }
}

/**
 * Track the page-view for all trackers.
 * Have in mind it already is triggered on all pages because it gets called by
 * PageTracker so we shouldn't need to use it anywhere else.
 */
export const trackPageView = (url: string, path: string) => {
  fbPixelTrackPageview()
  gaTrackPageview(url, path)
}

export const trackViewSwitch = (viewSwitchId: string, view: string) => {
  gaTrackerViewSwitch(viewSwitchId, view)
}
