import { ApolloError } from '@apollo/client'
import { GraphQLFormattedError } from 'graphql'
import { determineDeploymentEnvironment } from './determineEnvironment'

const logOnDev = (
  message: string | Error | ApolloError | GraphQLFormattedError
) => {
  if (determineDeploymentEnvironment().isLive) {
    return
  }

  /**
   * Prevent the log if the error has been created by us:
   * - For testing purposes.
   * - To inform the authentication failed because the user is not sign in.
   */
  const exceptionErrors = [
    MOCK_APOLLO_ERROR_MESSAGE,
    ...APOLLO_AUTH_ERROR_MESSAGE
  ]
  const errorObject = message instanceof Error || typeof message === 'object'
  const errorMessage = errorObject ? message?.message : message
  const hasExceptionError = exceptionErrors.some(msg =>
    errorMessage.includes(msg)
  )

  if (!hasExceptionError) {
    const logMessage = 'Log On Dev:' + errorMessage

    if (process.env.NODE_ENV === 'test') {
      // Throw error to force the test to fail
      throw logMessage
    } else {
      // eslint-disable-next-line no-console
      console.error(logMessage)
    }
  }
}

export default logOnDev

export const MOCK_APOLLO_ERROR_MESSAGE =
  'This is a mocked Apollo Error for testing purposes.'

/**
 * The AuthProvider throws this error message if the user isn't authenticated.
 *
 * This is how the auth works at the moment.
 * */
const APOLLO_AUTH_ERROR_MESSAGE = [
  // Consumer
  'field "enthusiasts" not found in type: \'query_root\'',
  `field "current_sign_in_at" not found in type: 'enthusiasts'`,
  // Business
  `field "current_sign_in_at" not found in type: 'instructors'`,
  'Instructor not authorised to modify this resource'
]
