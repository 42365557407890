import { ColorState, colorStates } from 'assets/theme/colors'

const determineColorForHover = (color: string): string => {
  const hoverColor = determineHasColor(color)

  /**
   * The color parameter has a generic type to simplify the TS setup and make this function more flexible.
   * The following error will inform us if we are using color that doesn't exist on colorStates.
   */
  if (!hoverColor) {
    throw new Error(
      `The parameter color (${color}) dosen't have a hover color.`
    )
  }

  return hoverColor
}

export default determineColorForHover

const determineHasColor = (color: string) => {
  let hoverColor: string | undefined
  Object.values(colorStates).forEach(item => {
    if (item.neutral === color) {
      /**
       * Using type assertion because the key accept any kind of string but
       * we won't use the 'hovered' as a key for ColorState.
       */
      hoverColor = item.hovered as ColorState['hovered']
    }
  })

  if (hoverColor) {
    return hoverColor
  }

  /**
   * If hoverColor is undefined it might be because this
   * color belogs to a subkey.
   */
  Object.values(colorStates).forEach(item => {
    Object.values(item).forEach(item => {
      if (item.neutral === color) {
        hoverColor = item.hovered
      }
    })
  })

  return hoverColor
}
