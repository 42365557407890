import { Schedulables } from 'shared/presenters/graphqlTypes'
import { MaybeRecursive } from 'shared/presenters/presenter'
import { generateCloudinaryUrl } from 'shared/helpers/generateCloudinaryUrl'
import { TransformationKeys } from 'shared/enums/image'

export interface DetermineImageFromSchedulableProps {
  image: MaybeRecursive<Schedulables['image']>
  defaultImage: MaybeRecursive<Schedulables['default_image']>
  activityType: MaybeRecursive<Schedulables['activity_type']>
  transform?: Extract<TransformationKeys, 'chargeable' | 'fullscreen'>
}

const determineImageFromSchedulable = ({
  image,
  defaultImage,
  activityType,
  transform = 'chargeable'
}: DetermineImageFromSchedulableProps) => {
  let imageURL = ''
  if (image) {
    imageURL = `image/upload/${image.split('image/upload/').slice(-1)[0]}`
  } else if (defaultImage?.image) {
    imageURL = defaultImage.image
  } else if (activityType?.key_image) {
    imageURL = activityType?.key_image
  }

  if (!imageURL) {
    return undefined
  }

  return generateCloudinaryUrl(imageURL, transform)
}

export default determineImageFromSchedulable
