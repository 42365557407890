import { KeyEvents } from 'shared/enums/keys'
import { MutableRefObject, useEffect } from 'react'

interface Props {
  ref?: MutableRefObject<HTMLElement | null>
}

/**
 * Gets keyboard-focusable elements within a specified element,
 * and filter out any disabled or hidden ones
 */
const getKeyboardFocusableElements = (element: HTMLElement) => {
  return [
    ...element.querySelectorAll<HTMLElement>(
      'a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])'
    )
  ].filter(
    el =>
      !el.hasAttribute('disabled') &&
      !el.getAttribute('aria-hidden') &&
      el.getAttribute('tabindex') !== '-1' &&
      getComputedStyle(el)?.display !== 'none'
  )
}

/**
 * Returns an OnKeyDown method that can be attached to an element in order to
 * trap the focus to only elements within a specified element e.g. a modal.
 */
const useFocusTrap = ({ ref }: Props) => {
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
    // TODO #6362 - Fix ESLint - hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref])

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key !== KeyEvents.tab || !ref?.current) return

    const eventTarget =
      event.target instanceof HTMLElement ? event.target : null
    if (eventTarget == null) return

    /**
     * It may seem inefficient to get the elements on every tab press, but this
     * list needs to refetched constantly to check for changes. e.g. Disabled
     * buttons could have changed to enabled based on form validation
     */
    const focusableElementsWithinRef = getKeyboardFocusableElements(ref.current)

    /**
     * if the current event target doesn't exist in our elements, the focus
     * is currently outside of the ref element. Here, we move the focus to the first
     * element in the ref element, ensuring we trap the user.
     */
    if (focusableElementsWithinRef.indexOf(eventTarget) === -1) {
      event.preventDefault()
      focusableElementsWithinRef[0].focus()
      return
    }

    /**
     * Given we are using onKeyDown, the event.target is still the same element as
     * BEFORE the user clicked the tab button. Below we want to know what the next element will be,
     * in order to trigger the next appropriate action.
     */
    const nextElementThatWouldBeInFocus = event.shiftKey
      ? focusableElementsWithinRef[
          focusableElementsWithinRef.indexOf(eventTarget) - 1
        ]
      : focusableElementsWithinRef[
          focusableElementsWithinRef.indexOf(eventTarget) + 1
        ]

    /*
     * If we can't find the next element in the array, it's means the we are
     * about to focus on an element outside of focus trap element. Instead of this,
     * we focus on the first or last element in focusableElementsWithinRef,
     * depending on if the user is tabbing or shift + tabbing.
     */
    if (nextElementThatWouldBeInFocus == null) {
      event.preventDefault()

      const firstOrLastFocusableElement = event.shiftKey
        ? focusableElementsWithinRef[focusableElementsWithinRef.length - 1]
        : focusableElementsWithinRef[0]

      firstOrLastFocusableElement.focus()
    }
  }
}

export default useFocusTrap
