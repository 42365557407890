import {
  determineDeploymentEnvironment,
  determineNodeEnvironment
} from 'shared/helpers/determineEnvironment'

const LOG_ERRORS_IN_DEVELOPMENT = false // Change it true to enable Sentry on development

/**
 * Determines whether to prevent sentry running or not.
 *
 * Note - we only want to log to the development area in Sentry if we have specified to do so
 * to prevent muddying the logs.
 */
const determineSentryIsEnabled = () => {
  const { isDevelopment, isTest, isProduction } = determineNodeEnvironment()
  const { isLive } = determineDeploymentEnvironment()

  if (
    (isDevelopment && !LOG_ERRORS_IN_DEVELOPMENT) ||
    isTest ||
    (isProduction && !isLive)
  ) {
    return false
  }

  return true
}

export default determineSentryIsEnabled
