import dynamic from 'next/dynamic'

export { default, StyledIconSVG } from './Icon'

/**
 * Reduce unused JavaScript by dynamically importing this component because
 * 'lottie-web/build/player/lottie_light' weights around 40kb.
 *
 * @note This component is mocked by 'jestSetup.js' to avoid dynamically importing
 * the component in the tests.
 */
export const AnimatedIcon = dynamic(() => import('./AnimatedIcon'), {
  loading: () => (
    <svg
      height={32}
      width={32}
      /**
       * When aria-live's attribute is set to polite, assistive technologies will
       * notify users of updates but generally do not interrupt the current task,
       * with the updates having a low priority.
       */
      role="status"
      aria-live="polite"
    ></svg>
  )
})

export type { IconNames } from 'enums/iconNames'
export type { ActivityTypeIconNames } from 'enums/activityTypeIconNames'
export type { IconActivityTypeNames } from './Icon'
export type { IconProps } from './Icon'
