/**
 * Utility types for helpers not provided by TypeScript.
 *
 * Links:
 * {@link https://www.typescriptlang.org/docs/handbook/utility-types.html TS Utility Types}
 */

/**
 * Optional<T, K>
 *
 * @note Use Partial (TS built-in) if you need to make all types optional.
 *
 * From T make a set of properties by key K become optional
 *
 * @example
 * type Props = { name: string; age: number; visible: boolean; };
 *
 * // Expect: { name?: string; age?: number; visible?: boolean; }
 * type Props = Optional<Props>
 *
 * // Expect: { name: string; age?: number; visible?: boolean; }
 * type Props = Optional<Props, 'age' | 'visible'>;
 */

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>

/**
 * Array Element
 *
 * It gets th element type from an array type.
 *
 * From `T` make a set of properties by key `K` become required
 *
 * @example
 * type Array = string[];
 *
 * const array: Array = ['a', 'b']
 *
 * // Expect: type Item = string
 * type Item = ArrElement<array>
 */
export type ArrayElement<ArrType extends readonly unknown[]> =
  ArrType extends readonly (infer ElementType)[] ? ElementType : never

// User-defined type guard for TypeScript narrow the type to NonNullable
export const determineIsNotNullish = <TValue>(
  value: TValue
): value is NonNullable<TValue> => {
  return value !== null && value !== undefined
}

/**
 * ObjectWithId<T, E>
 *
 * Formatting data in a way that we replace arrays to be key-value objects give us the opportunity to assign each
 * item with an ID that allows us to easily find and access these items.
 *
 * This type allows us to reinforce that the id is the same value as the name of the object to which the id belongs.
 * @example
 * type IdKeys = 'design' | 'library' | 'guests';
 *
 * // Expect:
 * {
 *    design: { id: "design"; title: string; content: string; };
 *    library: { id: "library"; title: string; content: string; };
 *    guests: { id: "guests"; title: string; content: string; };
 * };
 * type Data = ObjectWithId<IdKeys, { title: string; content: string }>;
 */
export type ObjectWithId<
  // Keys used as id
  T extends string,
  // Extra types to be injected with the id
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  E extends Record<string, any> = Record<string, any>
> = {
  [K in T]: { id: K } & E
}
