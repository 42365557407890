import { ON_SERVER } from 'shared/enums/environment'
import { determineNodeEnvironment } from 'shared/helpers/determineEnvironment'

const { isDevelopment, isTest } = determineNodeEnvironment()

/**
 * Adds element to the page in the test and development environments,
 * signifying if javascript has been loaded or not.
 *
 * Used in cucumber E2E testing to determine the page has loaded.
 */
const PageLoadedSignifier = () => {
  if (!(isDevelopment || isTest)) return null

  return (
    <div
      id="js-loaded"
      aria-hidden="true"
      style={{ display: 'none', visibility: 'hidden' }}
      /**
       * Suppress the error message because the serve and client will
       * never have the same result.
       */
      suppressHydrationWarning
    >
      {(!ON_SERVER).toString()}
    </div>
  )
}

export default PageLoadedSignifier
