import { defined, present, definedNotNull } from 'shared/presenters/presenter'
import { Bookings } from 'shared/presenters/graphqlTypes'
import {
  TimeSlotGroupPresenter,
  PartyPresenter,
  ArbitraryTransactionPresenter
} from 'shared/presenters'

export class BookingPresenter extends present<Bookings>() {
  adultsGoing = () => {
    const peopleGoing = this?.party?.people_going
    const childrenGoing = this?.party?.children_going
    if (peopleGoing !== undefined && childrenGoing !== undefined) {
      return peopleGoing - childrenGoing
    }
  }
  childrenGoing = () => this?.party?.children_going

  getOptionalExtrasQuantities = () => {
    const chosenOptionalExtras = this?.chosen_optional_extras
    return (
      chosenOptionalExtras &&
      Object.assign(
        {},
        ...chosenOptionalExtras.map(item => ({
          [defined(item.optional_extra?.id)]: defined(item.quantity)
        }))
      )
    )
  }

  getResourceRequirements = () => {
    const firstTimeSlot =
      typeof this.time_slot_booking != 'undefined' &&
      this.time_slot_booking !== null &&
      Array.isArray(this.time_slot_booking?.time_slots) &&
      this.time_slot_booking.time_slots.length > 0
        ? this.time_slot_booking.time_slots[0]
        : null
    const count: { [name: string]: number } = {}

    if (firstTimeSlot && firstTimeSlot.resource_assignment_placeholders) {
      const resources = firstTimeSlot.resource_assignment_placeholders.map(
        item => {
          return defined(item?.resource_requirement?.resource_type?.name)
        }
      )

      if (resources) {
        for (const resource of resources.filter(e => typeof e != 'undefined')) {
          if (count[resource]) {
            count[resource]++
          } else {
            count[resource] = 1
          }
        }
      }
    }

    return count
  }

  timeSlotGroup = () => {
    if (this.time_slot_group) {
      return new TimeSlotGroupPresenter(this.time_slot_group)
    }
  }

  mostRecentState = () =>
    defined(defined(this.transitions).find(t => t.most_recent))

  currentState = () => defined(this.mostRecentState().to_state)

  currentStateHumanized = () => {
    const sentence = this.currentState().replace(/_/g, ' ')
    return (
      sentence.slice(0, 1).toUpperCase() + sentence.slice(1, sentence.length)
    )
  }

  agendaState = () => {
    const currentState = this.currentState()
    const party = new PartyPresenter(definedNotNull(this.party))
    const allArbitraryTransactionsPaid = defined(
      this.arbitrary_transactions
    ).every(at => new ArbitraryTransactionPresenter(at).payment() !== null)

    if (currentState == 'cancelled') {
      return { css_class: 'error', message: 'Cancelled' }
    } else if (currentState == 'unconfirmed') {
      let msg = 'Not yet paid'
      if (!party.allDisclaimersAgreed()) {
        msg += allArbitraryTransactionsPaid ? ' and' : ','
        msg += ' disclaimers unsigned'
      }
      if (!allArbitraryTransactionsPaid)
        msg += ' and there are pending payments'
      return { css_class: 'warning', message: msg }
    } else if (!party.allDisclaimersAgreed()) {
      let msg = 'Not all disclaimers have been signed'
      if (!allArbitraryTransactionsPaid)
        msg += ' and there are pending payments'
      return { css_class: 'warning', message: msg }
    } else if (!allArbitraryTransactionsPaid) {
      return { css_class: 'warning', message: 'There are pending payments' }
    } else {
      return { css_class: '', message: 'All good' }
    }
  }

  stateClass = () => this.agendaState().css_class

  stateMessage = () => this.agendaState().message

  blockedOut = () => {
    const enthusiastNumber = this.party?.enthusiasts_parties?.find(ep =>
      defined(ep.organizer)
    )?.enthusiast?.contact_number

    if (enthusiastNumber) {
      return enthusiastNumber.includes('1234567')
    } else {
      return false
    }
  }
}
