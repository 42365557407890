import React from 'react'
import styled from '@emotion/styled'
import spritePath from 'assets/icons/sprite-icons.svg' // Import file instead of fetching from the public directory to make sure it is always updated and prevent caching.
import { IconNames, ActivityTypeIconNames } from '.'
import { margin, MarginProps } from 'styled-system'
import { Activity_Types } from 'shared/presenters/graphqlTypes'
import { defaultTheme } from 'assets/theme/theme'

export type IconActivityTypeNames =
  | ActivityTypeIconNames
  | ThisType<Pick<Activity_Types, 'css_class'>>

export interface IconProps extends MarginProps {
  name: IconNames | IconActivityTypeNames
  title?: string
  size?: number
  height?: number
  width?: number
  color?: keyof typeof defaultTheme.colors.icon | 'currentColor'
  fill?: string
  className?: string
  onClick?: () => void
}

/**
 * The color is optional because the Icon component has a default value in case this
 * prop is not declared. This attribute changes to required here just to inform the
 * `styled component` that this value is always present.
 */

type SVGProps = Required<Pick<IconProps, 'color'>>

/**
 * Renders an icon.
 *
 * Check out the ActivityTypeIcon component if you need an icon related to activity.
 * Please refer to `consumer/README.md` for instructions on how to add, edit or remove icons for use with this component.
 */
const Icon = ({
  name,
  size = 16,
  color = 'grey',
  fill,
  className,
  title,
  height,
  width,
  ...other
}: IconProps) => {
  return (
    <StyledIconSVG
      color={color}
      fill={fill}
      width={width ? width : size}
      height={height ? height : size}
      className={className}
      {...other}
    >
      {/* Use title for ARIA */}
      <title>{title ? `${title}` : `${name}`}</title>
      <use xlinkHref={`${spritePath}#${name}`} />
    </StyledIconSVG>
  )
}

export default styled(Icon)()

export const StyledIconSVG = styled.svg<SVGProps>(({ theme, color, fill }) => {
  const iconColors = {
    ...theme.colors.icon,
    currentColor: 'currentColor'
  }

  return {
    flexShrink: 0,
    use: {
      transition: `all ${theme.transitionTime[1]}s ease`,
      color: iconColors[color],
      fill: fill
    }
  }
}, margin)
