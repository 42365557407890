import React from 'react'
import Text, { DefaultFontProps } from 'library/atoms/Typography/Text'

const H3 = ({ children, ...other }: DefaultFontProps) => {
  return (
    <Text fontStyle="h3" as="h3" {...other}>
      {children}
    </Text>
  )
}

export default H3
