import { gql } from '@apollo/client'

export const FETCH_BASKET = gql`
  query WidgetBasketProviderFetchBasket($basketId: uuid, $locale: String!) {
    baskets(where: { id: { _eq: $basketId } }) {
      id
      created_at
      line_items {
        id
        updated_at
        price_paid
        price_modifier_type
        chargeable_type # This corresponds to which object below is set
        # Only 1 of the following will be present per line_item:
        arbitrary_transaction {
          name
          enthusiast_id
          booking {
            id
            schedulable {
              id
              image
              default_image {
                image
              }
              activity_type {
                key_image
              }
            }
          }
        }
        booking {
          id
          schedulable_id
          exclusive
          party {
            people_going
            children_going
            enthusiasts_parties {
              enthusiast_id
            }
          }
          schedulable {
            slug
            id
            price_pence
            duration_mins
            child_price_pence
            translations(
              where: {
                language: { _in: [$locale, "en"] }
                field: { _eq: "name" }
                model_type: { _eq: "Schedulable" }
              }
            ) {
              language
              field
              value_scrubbed
            }
            activity_type {
              id
              name
              slug
              css_class
              key_image
            }
            members_only
            image
            default_image {
              image
            }
            resource_requirements(where: { discarded_at: { _is_null: true } }) {
              id
              price_pence
              resource_type_id
              resource_type {
                id
                name
              }
            }
            arrive_before_mins
          }
          # TODO - REMOVE time_slot_group if we already have time_slot_booking ?
          time_slot_group {
            id
            finish
            start
          }
          time_slot_booking {
            finish
            start
            time_slots {
              id
              resource_assignment_placeholders {
                id
                resource_requirement_id
              }
            }
          }
          activity_voucher {
            redeemed
            id
            redemption_code {
              code
            }
            id
            booking {
              chosen_optional_extras {
                quantity
                optional_extra {
                  id
                  translations(
                    where: {
                      language: { _in: [$locale, "en"] }
                      field: { _in: ["name"] }
                      model_type: { _eq: "OptionalExtra" }
                    }
                  ) {
                    field
                    id
                    language
                    value_scrubbed
                  }
                }
              }
              party {
                children_going
                people_going
                organizer_participating
                booking {
                  chosen_optional_extras {
                    quantity
                    optional_extra {
                      id
                      translations(
                        where: {
                          language: { _in: [$locale, "en"] }
                          field: { _in: ["name"] }
                          model_type: { _eq: "OptionalExtra" }
                        }
                      ) {
                        field
                        id
                        language
                        value_scrubbed
                      }
                    }
                  }
                }
              }
              line_item {
                id
                basket_id
              }
            }
          }
          chosen_optional_extras {
            quantity
            optional_extra {
              id
              price_pence
              translations(
                where: {
                  language: { _in: [$locale, "en"] }
                  field: { _in: ["name"] }
                  model_type: { _eq: "OptionalExtra" }
                }
              ) {
                field
                id
                language
                value_scrubbed
              }
            }
          }
        }
        # Class Pass
        purchased_class_pass {
          enthusiast_id
          class_pass {
            price_pence
            max_redemptions
            translations(
              where: {
                language: { _in: [$locale, "en"] }
                field: { _in: ["name"] }
                model_type: { _eq: "ClassPass" }
              }
            ) {
              value_scrubbed
              field
              language
            }
            image
          }
        }
        purchased_store_item {
          quantity
          price_pence
          store_item {
            id
            translations(
              where: {
                language: { _in: [$locale, "en"] }
                field: { _in: ["name"] }
                model_type: { _eq: "StoreItem" }
              }
            ) {
              value_scrubbed
              field
              language
            }
          }
        }
        # Credit Voucher
        credit_voucher {
          id
          amount_pence
          enthusiast_id
        }
        # Activity Voucher
        activity_voucher {
          id
          booking {
            id
            exclusive
            party {
              people_going
              children_going
            }
            schedulable {
              id
              translations(
                where: {
                  language: { _in: [$locale, "en"] }
                  field: { _eq: "name" }
                  model_type: { _eq: "Schedulable" }
                }
              ) {
                language
                field
                value_scrubbed
              }
            }
            chosen_optional_extras {
              quantity
              optional_extra {
                id
                price_pence
                translations(
                  where: {
                    language: { _in: [$locale, "en"] }
                    field: { _in: ["name"] }
                    model_type: { _eq: "OptionalExtra" }
                  }
                ) {
                  field
                  id
                  language
                  value_scrubbed
                }
              }
            }
          }
        }
        # Memberships::Subscription
        membership_subscription {
          membership_price {
            membership_product {
              id
              translations(
                where: {
                  language: { _in: [$locale, "en"] }
                  field: { _in: ["name", "description"] }
                  model_type: { _eq: "Memberships::Product" }
                }
              ) {
                language
                field
                value_scrubbed
              }
            }
            stripe_identifier
            id
            unit_amount
            recurring_interval
            recurring_interval_count
            fixed_length # AKA renew subscription
            sign_up_fee
            translations(
              where: {
                language: { _in: [$locale, "en"] }
                field: { _in: ["nickname"] }
                model_type: { _eq: "Memberships::Price" }
              }
            ) {
              language
              field
              value_scrubbed
            }
          }
        }
      }
      line_items_aggregate {
        aggregate {
          count
        }
      }
      #
      # Basket Price Modifier for PriceModifierBar
      #
      baskets_price_modifiers_aggregate {
        aggregate {
          count
        }
      }
      baskets_price_modifiers {
        basket_id
        id
        price_modifier_type
        redemption_code {
          id
          code
        }
        discount_code {
          id
          percent
        }
        credit_voucher {
          id
          amount_pence
          amount_currency
          remaining_pence
        }
        purchased_class_pass {
          id
          redemptions_remaining
          class_pass {
            max_redemptions
            price_pence
            price_currency
            max_redemptions
            translations(
              where: {
                language: { _in: [$locale, "en"] }
                field: { _in: ["name"] }
                model_type: { _eq: "ClassPass" }
              }
            ) {
              value_scrubbed
              field
              language
            }
          }
        }
      }
    }
  }
`
