import { present, definedNotNull } from 'shared/presenters/presenter'
import { Time_Slot_Groups } from 'shared/presenters/graphqlTypes'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import dayjs from 'dayjs'

dayjs.extend(localizedFormat)
dayjs.extend(advancedFormat)

export class TimeSlotGroupPresenter extends present<Time_Slot_Groups>() {
  timeStartToEnd = () => {
    return `${dayjs(definedNotNull(this.start)).format('kk:mm')} -
                ${dayjs(definedNotNull(this.finish)).format('kk:mm')}`
  }
  dateText = () => {
    const startAsText = dayjs(definedNotNull(this.start)).format('ddd DD MMM')
    const finishAsText = dayjs(definedNotNull(this.finish)).format('ddd DD MMM')

    if (startAsText === finishAsText) {
      return startAsText
    } else {
      return `${startAsText} - ${finishAsText}`
    }
  }
  dateRangeText = () => {
    const startAsText = dayjs(definedNotNull(this.start)).format(
      'HH:mm - ddd DD MMM'
    )
    const finishAsText = dayjs(definedNotNull(this.finish)).format(
      'HH:mm - ddd DD MMM'
    )

    return `${startAsText} - ${finishAsText}`
  }
  duration = () => {
    return dayjs(definedNotNull(this.finish)).diff(
      dayjs(definedNotNull(this.start)),
      'minute'
    )
  }
  singleDay = () => {
    const startAsText = dayjs(definedNotNull(this.start)).format('ddd DD MMM')
    const finishAsText = dayjs(definedNotNull(this.finish)).format('ddd DD MMM')

    if (startAsText === finishAsText) {
      return true
    }

    return false
  }
}
