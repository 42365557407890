import { defined, definedNotNull, present } from 'shared/presenters/presenter'
import { Instructors } from 'shared/presenters/graphqlTypes'
import dayjs from 'dayjs'

export class InstructorPresenter extends present<Instructors>() {
  firstName = () => defined(this.first_name)

  lastName = () => defined(this.last_name)

  name = () => `${this.firstName()} ${this.lastName()}`

  inactive = () => defined(this.resources?.[0]?.inactive)

  activities = () =>
    defined(this.resources?.[0].resource_capabilities).map(rc => ({
      id: definedNotNull(rc.activity_type?.id),
      name: definedNotNull(rc.activity_type?.name)
    }))

  definedQualifications = () =>
    defined(this.qualifications).map(qualification => ({
      key: defined(qualification.id),
      id: defined(qualification.id),
      name: qualification.name ?? '',
      acquiredDate: dayjs(qualification.acquired_at ?? null),
      expiredDate: dayjs(qualification.expires_at ?? null)
    }))
}
