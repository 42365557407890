type EnvironmentVariables =
  | 'NEXT_PUBLIC_STRIPE_KEY'
  | 'NEXT_PUBLIC_HASURA_GRAPHQL_ENDPOINT'

const environmentVariables = {
  NEXT_PUBLIC_STRIPE_KEY: process.env.NEXT_PUBLIC_STRIPE_KEY,
  NEXT_PUBLIC_HASURA_GRAPHQL_ENDPOINT:
    process.env.NEXT_PUBLIC_HASURA_GRAPHQL_ENDPOINT
}

const criticalEnv = (name: EnvironmentVariables) => {
  if (!environmentVariables[name]) {
    throw new Error(`${name} is not defined and is critical`)
  }
  // Always return a string because we know the variable exists
  // String interpolation will avoid typescript error as potentially returns undefined
  return `${environmentVariables[name]}`
}

export default criticalEnv
