export enum HttpHeader {
  xForceRole = 'X-Force-Role',
  xHasuraUserId = 'X-Hasura-User-Id',
  xHasuraBasketId = 'X-Hasura-Basket-Id',
  xHasuraBookingInviteId = 'X-Hasura-Booking-Invite-Id',
  xHasuraRedemptionCode = 'X-Hasura-Redemption-Code',
  xHasuraDisclaimers = 'X-Hasura-Disclaimers',
  xHasuraAnswerSets = 'X-Hasura-Answer-Sets'
}

/**
 * Roles to be used in the 'X-Force-Role' header
 */
export enum XForceRole {
  public = 'public',
  instructor = 'instructor',
  enthusiast = 'enthusiast',
  /**
   * Sheep is a participant invited to a party organised by an enthusiast.
   */
  sheep = 'sheep',
  postCheckout = 'post_checkout'
}
