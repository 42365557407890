/**
 * Contains i18n configuration shared between projects
 */
module.exports = {
  locales: ['en', 'cy', 'es', 'pt', 'fr'],
  defaultLocale: 'en',
  loadLocaleFrom: (locale, namespace) => {
    const missingImport = err => {
      // Any import errors here get re-thrown as very confusing unrelated
      // errors by next-translate so we need to catch and just log instead.
      console.error('=========================================================')
      console.error(`ERROR MISSING NAMESPACE ${namespace} FOR LOCALE ${locale}`)
      console.error(err) // expected to be a module load error
      console.error('=========================================================')
      return {} // suppress the unhelpful next-translate error
    }
    if (namespace === 'dayjs') {
      // English is built in to DayJS so we don't want to load it (stuff breaks)
      if (locale === 'en') return Promise.resolve({})
      // Extract the locale information from DayJS and send it as JSON.
      // Most locales are effectively just JSON so this is reasonable,
      // a few use functions so will be incomplete when JSONified but
      // we can decide what to do about those if anyone asks for them.
      return import(`dayjs/locale/${locale}.js`)
        .then(m => {
          const originalLocale = m.default
          const modifiedJSONLocale = {
            ...originalLocale,
            ordinal: approximateDayJSOrdinal(originalLocale)
          }
          // Round-trip to get rid of any functions (which break next-translate)
          return JSON.parse(JSON.stringify({ locale: modifiedJSONLocale }))
        })
        .catch(missingImport)
    } else {
      // If we don't have translations for this locale, then fallback to english translations
      const langCode = AVAILABLE_LOCALES.includes(locale) ? locale : 'en'

      return import(`locales/${langCode}/${namespace}`)
        .then(m => m.default)
        .catch(missingImport)
    }
  }
}

const AVAILABLE_LOCALES = ['en', 'cy', 'es', 'pt', 'fr']

// Ordinal is for "1st", "2nd", etc.
// DayJS uses a function for ordinal which gets left out of the JSON.stringify
// so DayJS crashes.
// Luckily for most languages that aren't English it's just a degree sign or
// dot (or nothing) so we can easily achieve that without returning a function.
const approximateDayJSOrdinal = locale => {
  // ordinal is required and present in every locale except "en"
  if (typeof locale.ordinal !== 'function') return null

  // French special-cases 1 so don't use 1
  const testOrdinal = locale.ordinal(2)

  if (testOrdinal === '2.') return '.'
  else if (testOrdinal === '2º') return 'º'
  else return null // other languages will just get the number
}
