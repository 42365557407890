import { present } from 'shared/presenters/presenter'
import { translatablePresenter } from 'shared/presenters/translatablePresenter'
import { Categories } from 'shared/presenters/graphqlTypes'
import { DEFAULT_FALLBACK_IMAGE } from 'enums/image'

export class CategoryPresenter extends translatablePresenter(
  present<Categories>()
) {
  name = () => this.translateField('name')

  imageURL = () => {
    if (this.image) {
      return this.image
    }

    return DEFAULT_FALLBACK_IMAGE
  }
}
