import React from 'react'
import Text, { DefaultFontProps } from 'library/atoms/Typography/Text'

/**
 * H1 component for replacing the H1 element
 * Note - we have hard set the marginBottom 4 here - this will result in a header with no top margin
 * according to the logic coming from Text
 * This is desirable in most cases since we have a bottom margin on the header in the widget to create
 * that separation. If you want to add the marginTop in aswell you can either use a marginTop prop or
 * the marginVertical prop (see Text component)
 */
const H1 = ({ children, ...other }: DefaultFontProps) => {
  return (
    <Text fontStyle="h1" as="h1" {...other}>
      {children}
    </Text>
  )
}

export default H1
