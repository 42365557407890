const sharedI18n = require('shared/i18n')

module.exports = {
  ...sharedI18n,

  pages: {
    '*': [
      'common',
      'basket',
      'dayjs',
      'authentication',
      'outlets',
      'chargeable',
      'checkout'
    ],
    '/404': ['404'],
    'rgx:/basket': ['basket', 'common', 'chargeable'],
    'rgx:/checkout': ['checkout', 'common', 'chargeable', 'outlets'],
    '/[widgetSlug]/[categorySlug]': ['chargeable', 'common'],
    '/[widgetSlug]/[categorySlug]/[activitySlug]': [
      'outlets',
      'chargeable',
      'common'
    ],
    'rgx:/vouchers': ['chargeable'],
    'rgx:/rentals': ['chargeable'],
    'rgx:/memberships': ['chargeable'],
    'rgx:/memberships/[membershipSlug]': ['chargeable'],
    'rgx:/postcheckout': ['postCheckout'],
    'rgx:/booking-invite': ['postCheckout'],
    'rgx:/disclaimers': ['postCheckout'],
    'rgx:/question-packs': ['postCheckout'],

    // styleguide
    'rgx:/styleguide': ['chargeable'],

    // eola
    '/': ['home'],
    '/marketplace': ['marketplace'],
    '/search': ['search'],
    '/about-us': ['about', 'activityType']
  }
}
