export enum AccessStatus {
  authNotRequired = 'auth not required',
  loading = 'auth loading',
  authenticated = 'authenticated',
  unauthenticated = 'unauthenticated',
  authorised = 'authorised',
  unauthorised = 'unauthorised'
}

export enum AccessAction {
  allowAccess = 'allow access',
  redirectToLogin = 'redirect to login',
  indicateUnauthorised = 'indicate unauthorised',
  indicateLoading = 'indicate loading'
}

/**
 * determineAccess used for determining access for a user on a particular page based on their assigned outlets
 *
 * Note this is separate to permissions - which is more specific and looks at the roles for specific users to determine
 * changes that can be made at a feature level
 */
export const determineAccess = ({ authLoading }: { authLoading: boolean }) => {
  const authRequired = determineAuthRequired()

  if (!authRequired) {
    return {
      status: [AccessStatus.authNotRequired],
      action: AccessAction.allowAccess
    }
  }

  if (authLoading) {
    return {
      status: [AccessStatus.loading],
      action: AccessAction.indicateLoading
    }
  }
}

export default determineAccess

/**
 * This is a placeholder to help devs keep the code inline with
 * how things work in the Nebula project
 * please see the business project's determineAccess.ts for an example of how this might be developed
 */
export const determineAuthRequired = () => {
  return false
}
