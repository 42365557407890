import { defined, definedNotNull, present } from 'shared/presenters/presenter'
import { Arbitrary_Transactions } from './graphqlTypes'

export class ArbitraryTransactionPresenter extends present<Arbitrary_Transactions>() {
  outlet = () =>
    defined(
      definedNotNull(
        definedNotNull(definedNotNull(this.booking).schedulable).outlet
      )
    )

  payment = () =>
    definedNotNull(
      definedNotNull(definedNotNull(this.line_item).basket).payments
    )?.[0]

  paid = () => !!this.payment()
}
