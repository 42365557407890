import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import useTranslation from 'next-translate/useTranslation'
import Modal, { MODAL_MIXINS } from 'shared/library/molecules/Modal'
import { isLocalStorageAvailable } from 'shared/helpers/localStorageHelpers'
import Error from '../ModalContentError'

/**
 * This modal displays global notifications for the whole application.
 * */
const ModalsGlobal = () => {
  const { t } = useTranslation('common')
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  useEffect(() => {
    setModalOpen(!isLocalStorageAvailable())
  }, [])

  return (
    <>
      <StyledModal isOpen={modalOpen}>
        <Error errorMessage={t('incognito-error')} />
      </StyledModal>
    </>
  )
}

export default ModalsGlobal

const StyledModal = styled(Modal)(({ theme }) => MODAL_MIXINS.popup(theme))
