// Colors used in more than one variable
const genericColors = {
  primary: '#3E4EA0', // navy
  secondary: '#7584D2', // blue
  black: '#000',
  white: '#FFF',
  /**
   * The greys are meant only for this file. You can use them by reassigning
   * them to a specific variable. We are doing it to be easy to update some
   * of these colours without affecting all the elements.
   */
  greys: ['#222', '#666', '#8D979E', '#E4E4E4', '#F7F8FB', '#FBFBFB'],
  error: '#E05D39',
  eola: {
    greys: {
      90: '#1B1F2D',
      70: '#676B7B',
      50: '#C4C7D1',
      30: '#EEEEEE',
      10: '#F6F6F9'
    }
  }
}

export type ColorState = {
  neutral: string
  hovered: string
}

/**
 * colorStates are the colors that have variations based on the state of the element using this color.
 *
 * This is not destructured as part of `genericColors` because some of these color such as green and orange
 * are not supposed to be available on the core of the `color` variable but to be assigned to a key in
 * `color` e.g. `color.state.success`.
 *
 * Only exporting this const for `determineColorForHover.ts`.
 * These colors should be fetched by theme.colors on any other file apart from `determineColorForHover.ts`
 */
export const colorStates: {
  primary: ColorState
  green: ColorState
  orange: ColorState
  grey: { [key: string]: ColorState }
} = {
  primary: {
    neutral: genericColors.primary,
    hovered: '#203082'
  },
  green: {
    neutral: '#87C062',
    hovered: '#3B9600'
  },
  orange: {
    neutral: '#FF882A',
    hovered: '#C65F00'
  },
  grey: {
    1: {
      neutral: genericColors.greys[1],
      hovered: '#222'
    }
  }
}

const colors = {
  primary: genericColors.primary,
  black: genericColors.black,
  white: genericColors.white,
  error: genericColors.error,
  text: {
    primary: genericColors.greys[0],
    secondary: genericColors.greys[1],
    tertiary: genericColors.greys[2],
    placeholder: genericColors.greys[2],
    white: genericColors.white,
    black: genericColors.black,
    blue: genericColors.primary
  },
  background: {
    primary: genericColors.greys[5],
    secondary: genericColors.greys[4]
  },
  border: {
    primary: genericColors.greys[3],
    secondary: genericColors.primary,
    tertiary: genericColors.greys[2]
  },
  shadow: genericColors.primary,
  overlay: 'rgba(0, 0, 0, .25)',
  state: {
    error: genericColors.error,
    success: colorStates.green.neutral,
    disabled: genericColors.greys[2]
  },
  icon: {
    primary: genericColors.primary,
    darkGrey: genericColors.greys[0],
    grey: genericColors.greys[1],
    white: genericColors.white,
    success: colorStates.green.neutral,
    error: genericColors.error,
    gold: '#FFC108'
  },
  calendar: {
    available: {
      text: genericColors.white,
      background: colorStates.green.neutral
    },
    partiallyAvailable: {
      text: genericColors.white,
      background: colorStates.orange.neutral
    },
    unavailable: {
      text: genericColors.greys[2],
      background: genericColors.greys[3]
    },
    past: {
      text: genericColors.greys[3],
      background: genericColors.white
    }
  },
  timeSlot: {
    neutral: genericColors.greys[5],
    active: genericColors.primary,
    disabled: genericColors.greys[3],
    tag: {
      neutral: genericColors.greys[0],
      active: genericColors.secondary,
      disabled: genericColors.greys[2]
    }
  },
  styleguide: {
    border: genericColors.greys[5]
  },
  gradients: {
    primary: `linear-gradient(90DEG, ${genericColors.secondary} 0%, ${genericColors.primary} 100%)`
  },
  tabs: {
    tabList: {
      background: genericColors.eola.greys[10]
    }
  },
  // TODO - [eola.co - Homepage - fix before final merge] Review this structure
  // Waiting to confirm how often it gets used.
  eola: {
    ...genericColors.eola,
    layout: {
      background: {
        primary: genericColors.white,
        secondary: genericColors.eola.greys[10]
      }
    },
    bottomBar: {
      background: genericColors.eola.greys[10]
    }
  }
}

export default colors
