import {
  DeploymentEnvironment,
  NodeEnvironment
} from 'shared/enums/environment'

/**
 * Be aware, NodeEnvironment and DeploymentEnvironment values are not interchangable.
 * The 'Production' NodeEnvironment is NOT the same as the 'Live' DeploymentEnvironment.
 *
 * See the description of the enum values for more information.
 */
const {
  Production,
  Development,
  Test,
  Unknown: UnknownNodeEnv
} = NodeEnvironment
const { Live, Unknown: UnknownDeploymentEnv } = DeploymentEnvironment

/**
 * Used to determine the current Node Environment using APP_ENV,
 * which is based on the value of NODE_ENV.
 */
export const determineNodeEnvironment = () => {
  const isProduction = process.env.APP_ENV === Production
  const isDevelopment = process.env.APP_ENV === Development
  const isTest = process.env.APP_ENV === Test

  const environment = isProduction
    ? Production
    : isDevelopment
      ? Development
      : isTest
        ? Test
        : UnknownNodeEnv

  return {
    isProduction,
    isDevelopment,
    isTest,
    environment
  }
}

/**
 * Used to determine the current deployment environment, of which
 * we can only currently determine if the environment is "Live" or not "Live".
 *
 * "Live" refers only to the environment which is accessed by clients / enthusiasts.
 *
 * In most scenarios, `determineNodeEnvironment` should be sufficient. Rarely should
 * we be displaying changes in all deployed environments except for Live.
 */
export const determineDeploymentEnvironment = () => {
  const isLive = process.env.NEXT_PUBLIC_LIVE_APP === 'true'

  const environment = isLive ? Live : UnknownDeploymentEnv

  return {
    isLive,
    environment
  }
}
