import { rgba } from 'polished'
import { CoreTheme, ContentMaxWidth } from 'assets/theme/theme'
import colors from 'assets/theme/colors'
import capitalizeFirstLetter from './capitalizeFirstLetter'
import { CSSObject } from '@emotion/styled'

const generateMixins = (theme: CoreTheme) => {
  return {
    mixins: {
      /**
       * For applying a cut off and ellipsis to text once content reaches a specified line count
       */
      ellipsisLineClamp: (numberOfLines: number) =>
        ({
          WebkitLineClamp: numberOfLines,
          WebkitBoxOrient: 'vertical',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          display: '-webkit-box'
        }) as const,
      /**
       * For applying a cut off and ellipsis to text once content reaches a specified width
       */
      ellipsisWidthClamp: (maxWidth: number | string = '100%') =>
        ({
          display: 'inline-block',
          whiteSpace: 'nowrap',
          overflow: 'hidden !important',
          textOverflow: 'ellipsis',
          minWidth: 0,
          maxWidth
        }) as const,
      /**
       * Used typically to reduce elements appearing abruptly and
       * for softening renders
       */
      fadeIn: {
        animation: `${theme.transitionTime[1]}s ease 0s normal forwards 1 fadein`,
        '@keyframes fadein': {
          '0%': { opacity: 0 },
          '100%': { opacity: 1 }
        }
      },
      /**
       * Used for hiding a webkit auto fill button ::webkit-contacts-autofill-button
       * this can't be turned off using autocomplete
       */
      hideWebkitAutocomplete: () =>
        ({
          visibility: 'hidden',
          display: 'none !important',
          pointerEvents: 'none',
          height: '0',
          width: '0',
          margin: '0'
        }) as const,
      /**
       * Used for applying hover styles manually to icons that can't inherit the Button
       * component's baked in hover styles.
       */
      iconOnHover: {
        background: rgba(theme.colors.primary, 0.1),
        '& svg use': {
          color: theme.colors.primary
        }
      },
      /**
       * Used for adding maxWidth to the content and the centre aligns it.
       */
      centeredContainer: (
        size: keyof ContentMaxWidth = 'sm',
        sidePadding?: number,
        sideMargin?: number
      ) => {
        return {
          width: '100%',
          /**
           * If sidePadding is present it will take it in the count for calculating the max-width.
           */
          maxWidth:
            theme.contentMaxWidth[size] + theme.space[sidePadding ?? 0] * 2,
          marginRight: 'auto',
          marginLeft: 'auto',
          ...(sideMargin && {
            marginRight: theme.space[sideMargin],
            marginLeft: theme.space[sideMargin]
          }),
          ...(sidePadding && {
            paddingRight: theme.space[sidePadding],
            paddingLeft: theme.space[sidePadding]
          })
        }
      },
      /**
       * Note: "::first-letter" doesn't work with display flex.
       */
      capitalizeFirstLetter: () => {
        return {
          '&::first-letter': {
            textTransform: 'uppercase'
          }
        } as const
      },
      /**
       * Using background for the border instead of the border attribute because the dot needs a custom length.
       */
      dashedLine: (
        position: 'top' | 'right' | 'bottom' | 'left' = 'top',
        color: keyof typeof colors.border | 'currentColor' = 'primary'
      ) => {
        const horizontal = {
          backgroundDirection: 'bottom',
          dashes: '1px 8px',
          backgroundRepeat: 'repeat-y'
        }
        const vertical = {
          backgroundDirection: 'right',
          dashes: '8px 1px',
          backgroundRepeat: 'repeat-x'
        }

        const borderColor =
          color === 'currentColor' ? 'currentColor' : theme.colors.border[color]

        const determinePosition = () => {
          switch (position) {
            case 'top':
              return {
                ...vertical,
                backgroundPosition: '100% 0'
              }
            case 'right':
              return {
                ...horizontal,
                backgroundPosition: '100% 0'
              }
            case 'bottom':
              return {
                ...vertical,
                backgroundPosition: '0 100%'
              }
            // case 'left'
            default:
              return {
                ...horizontal,
                backgroundPosition: '0 100%'
              }
          }
        }

        const VALUES = determinePosition()

        return {
          backgroundImage: `linear-gradient(to ${VALUES.backgroundDirection}, ${borderColor} 0%, ${borderColor}  50%, transparent 50%)`,
          backgroundSize: VALUES.dashes,
          backgroundRepeat: VALUES.backgroundRepeat,
          backgroundPosition: VALUES.backgroundPosition,
          backgroundColor: 'transparent'
        }
      },
      positionAbsoluteMiddle: () =>
        ({
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }) as const,
      positionAbsoluteFillContent: () =>
        ({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%'
        }) as const,
      pxSpread: (numbers: number[]) => numbers.join('px ') + 'px',
      /**
       * Fix for Safari rendering flexbox with the wrong height.
       * This issue happens on Safari 14 and below while using "flex-direction: column" that has children with
       * flexible heigh e.g. "flex: 1 0 0" or "height: 100%".
       * It can only be seen on Browserstack and real devices. This issue can not be replicated by changing the
       * browser's user agent or Apple Simulator.
       *
       * There are two possible solutions for this issue:
       * - Replace "flex-direction: column" with "flex-direction: row; flex-wrap: wrap".
       * - Keep "flex-direction: column" and add "min-height: fit-content" to the children with flexible height.
       */
      fixFlexibleHeightForChildrenInFlexColumn: targetSafari({
        minHeight: 'fit-content'
      } as const),
      /**
       * It creates a pseudo space for cases where we need to create space for something without affecting
       * neighboring elements.
       */
      createPseudoSpace: ({
        position,
        size
      }: {
        position: 'top' | 'right' | 'bottom' | 'left'
        size: number
      }) =>
        ({
          [`padding${capitalizeFirstLetter(position)}`]: theme.space[size],
          [`margin${capitalizeFirstLetter(position)}`]: -theme.space[size]
        }) as const,
      /**
       * eola's mixins
       * The following mixins should only be used by elements part of the public-facing AKA eola.
       */
      eola: {
        link: {
          textDecoration: 'none',
          transition: `${theme.transitionTime[1]}s ease`,
          '&:hover': {
            textDecoration: 'underline'
          }
        }
      }
    }
  }
}

export default generateMixins

const targetSafari = (style: CSSObject) =>
  ({
    [`@media not all and (min-resolution:.001dpcm)`]: {
      [`@supports (-webkit-appearance:none)`]: style
    }
  }) as const
