/**
 * Not clear why these properties are not supported natively probably because
 * they are used in older browsers.
 */
interface NavigatorExtended extends Navigator {
  userLanguage: string
  browserLanguage: string
  systemLanguage: string
}

/**
 * This method takes browser settings for languages.
 *
 * navigator.languages is the most recently introduced method of
 * determining lanugages in modern browsers
 *
 * navigator.language === naviagator.userLanguage in IE11
 *
 * navigator.systemLanguage = this is the fallback and is used
 * to determine the language of the OS
 */
const getBrowserLanguage = () => {
  if (typeof window === 'undefined') {
    // default to en if there is nothing set
    return 'en'
  }
  const navigator = window.navigator as NavigatorExtended

  /**
   * todo - in the future we want a way for the user to save their preferred
   * language to the backend. We would want to prioritise over the other scenarios
   */

  return (
    navigator.languages[0] ||
    navigator.language ||
    navigator['userLanguage'] ||
    navigator['browserLanguage'] ||
    navigator['systemLanguage'] ||
    'en-GB'
  )
}

export default getBrowserLanguage
