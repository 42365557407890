import React, { ReactNode, createContext } from 'react'
import { gql, QueryResult, useQuery } from '@apollo/client'
import { Query_Root } from 'shared/presenters/graphqlTypes'
import { InstructorPresenter } from 'shared/presenters'
import { HttpHeader, XForceRole } from 'shared/enums/httpHeader'
import useAuthState from './useAuthState'

export interface InstructorAuthProps {
  children: ReactNode
}

export interface InstructorAuthContextProps {
  authenticated: boolean
  setAuthenticated: (authenticated: boolean) => void
  authLoading: boolean
  instructor?: InstructorPresenter
  refetchAuth: () => void
}

export const InstructorAuthContext = createContext<InstructorAuthContextProps>({
  authenticated: false,
  setAuthenticated: () => {
    throw new Error('useInstructor must be used within InstructorAuthProvider')
  },
  authLoading: true,
  refetchAuth: () => {
    throw new Error('useInstructor must be used within InstructorAuthProvider')
  }
})

/**
 * It creates a context for the App to check if the user is current signed in.
 *
 * Note:
 * It only covers instructor users. If the user is authenticated, it will also return the user's name
 * to avoid extra Hasura calls while using the useInstructor hook. This logic might change in the future
 * if it is necessary to cover other kinds of users or need to fetch some complex data.
 */
const InstructorAuthProvider = ({ children }: InstructorAuthProps) => {
  const {
    refetch,
    data,
    error,
    loading
  }: QueryResult<Pick<Query_Root, 'Business_Sessions_Current'>> = useQuery(
    FETCH_INSTRUCTOR,
    {
      context: { headers: { [HttpHeader.xForceRole]: XForceRole.instructor } }
    }
  )

  const { authenticated, setAuthenticated, authLoading, user } = useAuthState(
    data?.Business_Sessions_Current?.instructor,
    loading,
    refetch,
    error
  )

  return (
    <InstructorAuthContext.Provider
      value={{
        authenticated,
        setAuthenticated,
        authLoading,
        instructor: user as InstructorPresenter,
        refetchAuth: refetch
      }}
    >
      {children}
    </InstructorAuthContext.Provider>
  )
}

export default InstructorAuthProvider

export const FETCH_INSTRUCTOR = gql`
  query BusinessInstructorAuthProviderFetchInstructor {
    Business_Sessions_Current {
      instructor {
        id
        first_name
        last_name
        profile_picture
        instructors_outlets {
          outlet {
            id
            name
            slug
          }
          permission_preset {
            name
            permission_mappings {
              feature
              read_own
              read_all
              write_own
              write_all
            }
          }
        }
      }
    }
  }
`
