import { present } from 'shared/presenters/presenter'
import { Baskets } from 'shared/presenters/graphqlTypes'
import { MEMBERSHIPS_CHARGEABLE_TYPE } from 'shared/enums/memberships'
import isVoucherType from 'shared/helpers/isVoucherType'
import { ChargeableType } from 'shared/enums/chargeableType'
import {
  LineItemPresenter,
  PartyPresenter,
  AnswerSetsPresenter,
  BookingPresenter
} from 'shared/presenters'

export class BasketPresenter extends present<Baskets>() {
  getBookingLineItems = () => {
    return this?.line_items?.filter(
      item =>
        item.chargeable_type && item.chargeable_type === ChargeableType.Booking
    )
  }

  hasLineItemBookingForMembersOnly = () => {
    // return false if line_items is undefined
    return !!this?.line_items?.some(
      item => !!item?.booking?.schedulable?.members_only
    )
  }

  hasVoucher = () => {
    return !!this?.line_items?.some(
      item =>
        item.chargeable_type === 'CreditVoucher' ||
        item.chargeable_type === 'PurchasedClassPass'
    )
  }

  hasIncompleteBooking = () => {
    // return false if line_items is undefined
    return !!this?.line_items?.some(
      item =>
        !!item?.booking?.activity_voucher && !item?.booking?.time_slot_booking
    )
  }

  hasMembershipSubscription = () => {
    // return false if line_items is undefined
    return !!this?.line_items?.some(
      item => item.chargeable_type === MEMBERSHIPS_CHARGEABLE_TYPE
    )
  }

  findMembershipSubscription = () => {
    return this?.line_items?.find(
      item => item.chargeable_type === MEMBERSHIPS_CHARGEABLE_TYPE
    )?.membership_subscription
  }

  getAnswerSets = () => {
    const idList: string[] = []

    // collect all sub arrays of multiple obkjects in one array

    const answerSets = this.line_items?.flatMap(
      item => new LineItemPresenter(item)?.getAnswerSets() ?? []
    )

    if (!answerSets) {
      return []
    }

    const filteredItems = answerSets.filter(item => {
      const id = item?.id
      if (id && idList?.indexOf(id) === -1) {
        idList.push(id)
        return true
      }
      return false
    })

    return filteredItems || []
  }

  getAnsweredAnswerSets = () => {
    const answerSets = this.getAnswerSets()
    const answeredAnswerSets = answerSets?.filter(
      item => item?.answered == true
    )
    return answeredAnswerSets || []
  }

  getAgreedDisclaimers = () => {
    const disclaimers = this.getDisclaimers()
    const agreedDisclaimers = disclaimers?.filter(item => item?.agreed == true)
    return agreedDisclaimers || []
  }

  getLineItemIds = () => {
    const lineItemIds: (string | undefined)[] = []
    this?.line_items?.map(item => {
      lineItemIds.push(item?.id)
    })
    return lineItemIds
  }

  getSheepCount = () => {
    return this.getBookingLineItems()
      ?.map(item => {
        const party = new PartyPresenter(item?.booking?.party ?? {})
        return party.sheepCount()
      })
      ?.reduce((partialSum = 0, count = 0) => partialSum + count, 0)
  }

  getPeopleGoingCount = () => {
    return this.getBookingLineItems()
      ?.map(item => {
        const party = new PartyPresenter(item?.booking?.party ?? {})
        return party.peopleGoingCount()
      })
      ?.reduce((partialSum = 0, count = 0) => partialSum + count, 0)
  }

  getPeopleInvitedCount = () => {
    return this.getBookingLineItems()
      ?.map(item => {
        const party = new PartyPresenter(item?.booking?.party ?? {})
        return (
          party.peopleGoingCount() -
          (party.uninvitedAdultsCount() + party.uninvitedChildrenCount())
        )
      })
      ?.reduce((partialSum = 0, count = 0) => partialSum + count, 0)
  }

  getLineItemsWithInvitedSheep = () => {
    return this.line_items?.filter(item => {
      const party = new PartyPresenter(item?.booking?.party ?? {})
      return party?.areAllSheepInvited()
    })
  }

  areAllSheepInvited = () => {
    const lineItemsWithInvitedSheep = this.getLineItemsWithInvitedSheep()
    return lineItemsWithInvitedSheep?.length === this.line_items?.length
  }

  getVouchersCount = () => {
    const voucherList = this?.line_items?.filter(
      item => item.chargeable_type && isVoucherType(item.chargeable_type)
    )

    return voucherList?.length ?? 0
  }

  getDisclaimers = () => {
    const idList: number[] = []

    // collect all sub arrays of multiple obkjects in one array

    const disclaimers = this.line_items?.flatMap(
      item => new LineItemPresenter(item)?.getDisclaimers() ?? []
    )

    if (!disclaimers) {
      return []
    }

    const filteredItems = disclaimers.filter(item => {
      const id = item?.id
      if (id && idList?.indexOf(id) === -1) {
        idList.push(id)
        return true
      }
      return false
    })

    return filteredItems || []
  }

  // This is a duplicate of method above but for invitees. Im not sure
  // i understand why we use orgamizer participating so duplicated until
  // i understand better
  getLineItemsWithUniqueDisclaimers = () => {
    const idList: number[] = []

    const filteredItems = this.line_items?.filter(item => {
      const disclaimers = new LineItemPresenter(item)?.getDisclaimers()

      if (!disclaimers) {
        return false
      }

      let unique = false

      disclaimers.forEach(disclaimer => {
        const id = disclaimer?.id
        if (id && idList?.indexOf(id) === -1) {
          idList.push(id)
          unique = true
        }
      })

      return unique
    })

    return filteredItems
  }

  getLineItemsWithAnswerSets = () => {
    const filtered_line_items = this.line_items?.filter(
      item => new LineItemPresenter(item).getAnswerSets().length > 0
    )

    return filtered_line_items
  }

  getLineItemsWithDisclaimers = () => {
    return this.line_items?.filter(
      item => new LineItemPresenter(item)?.getDisclaimer()?.id
    )
  }

  getDisclaimerAgreedTime = () => {
    return this.getLineItemsWithDisclaimers()?.[0]?.booking?.party?.disclaimers_parties?.[0].disclaimer?.time_of_agreeing?.toString()
  }

  getDisclaimerAgreedTimeDate = () => {
    const disclaimerAgreedTime = this.getDisclaimerAgreedTime()
    if (disclaimerAgreedTime) {
      return new Date(disclaimerAgreedTime).getTime()
    }
  }

  getDisclaimerAgreedTimeDifferenceInMinutes = () => {
    const timeNowDate = new Date().getTime()
    const disclaimerAgreedTimeDate =
      this.getDisclaimerAgreedTime() && this.getDisclaimerAgreedTimeDate()
    const timeDiffInMilliseconds =
      disclaimerAgreedTimeDate &&
      Math.abs(timeNowDate - disclaimerAgreedTimeDate)
    const timeDiffInMinutes =
      timeDiffInMilliseconds && timeDiffInMilliseconds / 60000
    return timeDiffInMinutes
  }

  getLineItemBookingFromActivityVoucher = () => {
    const activityVoucherBooking = this.line_items?.filter(
      item => item.booking?.activity_voucher?.booking
    )
    return (
      activityVoucherBooking?.[0]?.booking &&
      new BookingPresenter(activityVoucherBooking?.[0]?.booking)
    )
  }

  getActivityVoucherLineItemId = () => {
    return this.getLineItemBookingFromActivityVoucher()?.activity_voucher
      ?.booking?.line_item?.id
  }

  getActivityVoucherCode = () => {
    return this.getLineItemBookingFromActivityVoucher()?.activity_voucher?.redemption_code?.toString()
  }

  // Checks whether the basket contains other answer sets with the same question pack as the given answer set.
  // The answer sets must also belong to the same user as the given answer set.
  // If a guardian has the same answer set to answer for themselves and for a child this will return false.
  sharesQuestionPackFor = (answerSet: AnswerSetsPresenter) => {
    if (this.line_items?.length == 1) return false

    const sharingQuestionPack = []

    this.line_items?.forEach(item => {
      if (item?.chargeable_type != ChargeableType.Booking) return

      item?.booking?.answer_sets?.forEach(set => {
        if (set?.question_pack_id != answerSet.question_pack_id) return
        if (set?.enthusiast?.name != answerSet.enthusiast?.name) return
        if (set?.child?.name != answerSet.child?.name) return

        sharingQuestionPack.push(set)
      })
    })

    return sharingQuestionPack.length > 1
  }
}
