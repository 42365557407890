import React, {
  createContext,
  useState,
  useEffect,
  PropsWithChildren
} from 'react'
import { useQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import { Baskets, Basket_Response } from 'shared/presenters/graphqlTypes'
import { BasketPresenter } from 'shared/presenters'
import { ON_SERVER } from 'shared/enums/environment'
import storageApi from 'helpers/storageApi'
import { FETCH_BASKET } from './basketProviderFetchBasketQuery'
import { HttpHeader } from 'shared/enums/httpHeader'

type BasketIdFromResponse = Basket_Response['basket_id']

export type BasketProviderProps = PropsWithChildren<{
  /**
   * Only used for setting initial value while running tests.
   */
  initialBasketIdForTest?: BasketIdFromResponse
}>

type BasketQueryResult = {
  baskets: Baskets[]
}
type BasketQueryVars = {
  basketId: string | null
  locale: string
}
type BasketQueryType = BasketQueryResult & BasketQueryVars

export interface BasketContextProps {
  refetchBasket: ({
    basketId
  }: {
    /**
     * Using Maybe<string> | undefined to remove the need to use `defined` every time we need to declare the basketId.
     */
    basketId?: BasketIdFromResponse
  }) => void
  basketLoading: boolean
  basket?: BasketPresenter
}

export const BasketContext = createContext<BasketContextProps>({
  refetchBasket: () => {
    throw new Error('useBasket must be used within BasketProvider')
  },
  basketLoading: true
})

/**
 * It creates a context for the App to render the basket data.
 */
const BasketProvider = ({
  children,
  initialBasketIdForTest
}: BasketProviderProps) => {
  const { locale, query } = useRouter()
  const widgetSlug = query.widgetSlug as string
  const [basket, setBasket] = useState<BasketPresenter | undefined>(undefined)
  const [performRefetch, setPerformRefetch] = useState(false)

  /**
   * We use initialBasketIdForTest to simplify mocking the BasketProvider on tests, it allow us to
   * declared the initial basket id via the provider instead of having to mock the storageApi.
   *
   * Use initialBasketIdForTest if value is null or string, otherwise, if value id undefined it will
   * default as not being used for tests and get the value from storageApi.
   */
  const basketIdLocalStorage =
    initialBasketIdForTest !== undefined
      ? initialBasketIdForTest
      : storageApi.getBasketId?.(widgetSlug)

  /**
   * We store basketId in state because we need to use it for useQuery.context.headers.xHasuraBasketId (we cannot pass context headers as params to `refetch`)
   *   - https://github.com/apollographql/apollo-client/blob/main/src/core/ObservableQuery.ts#L355
   */
  const [basketIdToFetch, setBasketIdToFetch] =
    useState<BasketIdFromResponse>(basketIdLocalStorage)

  const { loading: basketLoading, refetch } = useQuery<BasketQueryType>(
    FETCH_BASKET,
    {
      variables: {
        basketId: basketIdToFetch,
        locale
      },
      onCompleted: response => {
        const basketIdFromResponse = response?.baskets[0]?.id
        if (!basketIdFromResponse) {
          resetBasket()
          return
        }

        setBasket(new BasketPresenter(response?.baskets[0]))

        setBasketIdToFetch(basketIdFromResponse)
      },
      skip: ON_SERVER || !basketIdToFetch,
      context: { headers: { [HttpHeader.xHasuraBasketId]: basketIdToFetch } },
      ssr: ON_SERVER,
      notifyOnNetworkStatusChange: true
    }
  )

  /**
   * refetchBasket sets the basket id to use in the refetch query
   * We need to allow the state to update before refetching the query
   * otherwise basketId can be undefined when refetching
   */
  useEffect(() => {
    if (!performRefetch || !basketIdToFetch) return

    setPerformRefetch(false)
    refetch()
  }, [performRefetch])

  /**
   * We export a refetchBasket wrapper to enable us to reset the basket if applicable
   */
  const refetchBasket: BasketContextProps['refetchBasket'] = async ({
    basketId
  }) => {
    if (!basketId) {
      resetBasket()
      return
    }

    storageApi.setBasket({
      widgetSlug,
      basketId
    })

    setBasketIdToFetch(basketId)
    setPerformRefetch(true)
  }

  const resetBasket = () => {
    storageApi.removeBasket(widgetSlug)
    setBasketIdToFetch(undefined)
    setBasket(undefined)
  }

  return (
    <BasketContext.Provider
      value={{
        basketLoading,
        refetchBasket,
        basket
      }}
    >
      {children}
    </BasketContext.Provider>
  )
}

export default BasketProvider
