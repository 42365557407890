import React from 'react'
import { css, Global, useTheme } from '@emotion/react'
import { normalize } from 'polished'
import useDetermineProject from 'shared/state/useDetermineProject'
import detectIfInIframe from 'helpers/detectIfInIframe'

/**
 * Generate global CSS.
 *
 * @warning Don't import fonts in this file because it causes issues to render pages on iPhone,
 * see issue #3856 for more information. Next.js offers font optimization for this.
 *
 * Links:
 * {@link https://github.com/eola/eola/pull/3856 issue #3856}
 * {@link https://nextjs.org/docs/basic-features/font-optimization font optimization}
 */
const GlobalStyle = () => {
  const theme = useTheme()
  const project = useDetermineProject()
  const isInsideIframe = detectIfInIframe()

  return (
    <Global
      styles={css`
        ${normalize()},
        *,
          *::before,
          *::after {
          box-sizing: inherit;
        }
        html,
        body {
          padding: 0;
          margin: 0;
          ${project === 'widget' &&
          `
            background: ${
              isInsideIframe ? 'transparent' : theme.colors.background.secondary
            };
          `}
          height: 100%;
          min-height: 100%;
          width: 100%;
          font-family: ${theme.fonts.body};
          box-sizing: border-box;
        }
        a {
          cursor: pointer;
          text-decoration: underline;
          color: currentcolor;
        }
        fieldset {
          border: none;
        }
        #__next {
          display: flex;
          flex-flow: column;
          height: 100%;
        }
        p br:only-child {
          display: none;
        }
      `}
    />
  )
}
export default GlobalStyle
