import { Chosen_Optional_Extras } from 'shared/presenters/graphqlTypes'
import { present, definedNotNull } from 'shared/presenters/presenter'
import { OptionalExtraPresenter } from './OptionalExtraPresenter'
export class ChosenOptionalExtraPresenter extends present<Chosen_Optional_Extras>() {
  name = () => {
    const optionalExtra = new OptionalExtraPresenter(
      definedNotNull(this.optional_extra)
    )
    return optionalExtra.name()
  }
}
