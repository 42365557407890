export enum NodeEnvironment {
  /**
   * Refers to the environment which is accessed by clients / enthusiasts,
   * and the environments deployed via Vercel.
   */
  Production = 'production',
  /**
   * Refers to the environment in which changes are developed.
   */
  Development = 'development',
  /**
   * Refers to the environment in which tests are run.
   */
  Test = 'test',
  Unknown = 'unknown'
}

export enum DeploymentEnvironment {
  /**
   * Refers only to the environment which is accessed by clients / enthusiasts.
   */
  Live = 'live',
  Unknown = 'unknown'
}

/**
 * Identify whether something is running client side or not in next methods see
 * https://github.com/vercel/next.js/issues/5354#issuecomment-520305040 for the
 * recommendation.
 */
export const ON_SERVER = typeof window === 'undefined'
