import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import setLanguage from 'next-translate/setLanguage'
import * as Sentry from '@sentry/nextjs'
import getBrowserLanguage from 'helpers/getBrowserLanguage'
import useTranslation from 'next-translate/useTranslation'
import { setDayJSLocale } from 'state/useSetupLocale'
import { setJSLocale } from 'shared/helpers/setJSLocale'

const getSimplifiedLocale = (locale: string) => locale.split('-')[0]

/**
 * Find the locale set going from the most specific to the least specific based on the options available (locales param)
 *
 * e.g. if locales are ['de', 'fr', 'ko'] and the desired Lang is 'de-CH' then the most specific locale available would be 'de'
 *
 * If locales are instead ['cy','fr','ko'] and the desiredLang is still 'de-CH' then the fallback would be 'en'
 */
export const getLocaleToSet = (
  desiredLang: string,
  availableLocales: string[]
) => {
  if (availableLocales.indexOf(desiredLang) > -1) {
    return desiredLang
  }

  if (availableLocales.indexOf(getSimplifiedLocale(desiredLang)) > -1) {
    return getSimplifiedLocale(desiredLang)
  }

  return 'en'
}

const setNextJSLocale = async (
  localeToSet: string,
  browserLang: string,
  currentNextJSLocale: string
) => {
  if (getSimplifiedLocale(browserLang) === currentNextJSLocale) {
    return
  }

  if (localeToSet !== browserLang) {
    Sentry.captureMessage(
      `Warning - we couldn't find a nextjs locale for the user's specified browser language - '${browserLang}'. Falling back to ${localeToSet}. Consider adding this locale to support the this case for future scenarios `
    )
  }
  await setLanguage(localeToSet)
}

const useSetupLocale = () => {
  const [dayJSLoaded, setDayJSLoaded] = useState<boolean>(false)
  const browserLang = getBrowserLanguage()
  const { t } = useTranslation('dayjs')
  const { defaultLocale, locale, locales } = useRouter()
  const currentNextJSLocale = locale as string
  const availableNextJSLocales = locales as string[]

  /**
   * Needed to prevent infinitely navigating.
   *
   * If user navigates to url without a locale (is using the app using the default english lang)
   * i.e. eola.co/[widgetSlug] Then we try to do our best at determining what lang to use based on broswer settings.
   * Else - the user have navigated / has been redirected to a locale prefixed url i.e. eola.co/cy/[widgetSlug])
   * Therefore we do not take into account their desired browser settings and assume that the locale in their url is their intended lang.
   */

  useEffect(() => {
    const localeToSet =
      currentNextJSLocale === defaultLocale
        ? getLocaleToSet(browserLang, [...availableNextJSLocales])
        : getLocaleToSet(currentNextJSLocale, [...availableNextJSLocales])

    // Needed to switch DayJS locale when client-side routing
    setDayJSLoaded(false)
    /**
     * Don't attempt locale redirect on backend as it would always be "en"
     * the backend will respect whatever locale is set in the URL and thus
     * correctly static-render or server-render in the locale requested.
     */
    if (typeof window !== 'undefined')
      setNextJSLocale(localeToSet, browserLang, currentNextJSLocale)
    setJSLocale(localeToSet)
    // TODO #6362 - Fix ESLint - hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentNextJSLocale])
  // Needs to be outside useEffect else there's a flash of the wrong date locale
  if (!dayJSLoaded) setDayJSLocale(currentNextJSLocale, t, setDayJSLoaded)
}

export default useSetupLocale
