import React, { ReactNode } from 'react'
import EnthusiastAuthProvider from './EnthusiastAuthProvider'
import InstructorAuthProvider from './InstructorAuthProvider'

export interface AuthProviderProps {
  children: ReactNode
  project: 'business' | 'consumer'
}

/**
 * AuthProvider handles the authentication for different types of users such as instructors
 * and enthusiasts. Each of eola's projects demands different combinations of authentication,
 * the AuthProvider determines which provider should be rendered.,
 */
const AuthProvider = ({ children, project }: AuthProviderProps) => {
  const Provider = Providers[project]
  return <Provider>{children}</Provider>
}

export default AuthProvider

const Providers = {
  business: ({ children }: { children: ReactNode }) => (
    <InstructorAuthProvider>{children}</InstructorAuthProvider>
  ),
  consumer: ({ children }: { children: ReactNode }) => (
    <InstructorAuthProvider>
      <EnthusiastAuthProvider>{children}</EnthusiastAuthProvider>
    </InstructorAuthProvider>
  )
}
