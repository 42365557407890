import React from 'react'
import { AnimatedIcon } from 'shared/library/atoms/Icon'
import { H4, Text } from 'library/atoms/Typography'
import Button from 'library/atoms/Button'
import useTranslation from 'next-translate/useTranslation'

export interface ModalContentErrorProps {
  errorMessage?: string
  /**
   * Pass this parameter as callback because this component is also used for single modals.
   * Single modals don't have access to setActiveModalId.
   *
   * This parameter is optional because there are cases when we want to show an error and
   * don't let the user progress any further.
   */
  onClose?: () => void
}

type ErrorModal = (props: ModalContentErrorProps) => JSX.Element

const ModalContentError: ErrorModal = ({ errorMessage, onClose }) => {
  const { t } = useTranslation('common')

  return (
    <>
      <AnimatedIcon name="exclamation" size={64} autoplay />
      <div>
        <H4 mt={3}>{t('oops')}</H4>
        <Text paragraphMargins color="secondary">
          {errorMessage}
        </Text>
      </div>
      {!!onClose && (
        <Button onClick={onClose} mt={5}>
          {t('ok')}
        </Button>
      )}
    </>
  )
}

export default ModalContentError
