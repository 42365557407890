import { useState, useRef } from 'react'

/**
 * Used for syncing state with a React ref
 *
 * This is useful in scenarios where state becomes stale due to closures. This is seen in particular when reading stateful variables in event listeners
 */
const useStateRef = <T>(initialValue: T) => {
  const [state, setState] = useState<T>(initialValue)
  const stateRef = useRef<T>(state)

  if (stateRef.current !== state) stateRef.current = state

  return { state, setState, stateRef }
}

export default useStateRef
