import React from 'react'
import Text, { DefaultFontProps } from 'library/atoms/Typography/Text'

const H6 = ({ children, ...other }: DefaultFontProps) => {
  return (
    <Text fontStyle="h6" as="h6" {...other}>
      {children}
    </Text>
  )
}

export default H6
