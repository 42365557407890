import { defined, definedNotNull, present } from 'shared/presenters/presenter'
import {
  EnthusiastPresenter,
  ChildPresenter,
  InvitedEnthusiastPresenter,
  InvitedChildPresenter
} from 'shared/presenters'
import { Parties } from 'shared/presenters/graphqlTypes'

export class PartyPresenter extends present<Parties>() {
  get adults_going() {
    if (this.people_going !== undefined && this.children_going !== undefined)
      return this.people_going - this.children_going
  }

  peopleGoingCount = () => this.people_going ?? 0
  childrenGoingCount = () => this.children_going ?? 0
  adultsGoingCount = () => {
    return this.peopleGoingCount() - this.childrenGoingCount()
  }

  organizer = () =>
    new EnthusiastPresenter(
      (
        defined(this.enthusiasts_parties).find(ep => defined(ep.organizer)) ||
        {}
      ).enthusiast || {}
    )

  formattedSize = () => {
    const { adults_going, children_going } = this
    let adultsSizeText = ''
    let childrenSizeText = ''
    const separator = adults_going && children_going ? ', ' : ''

    if (adults_going) {
      adultsSizeText = `${adults_going} ${
        adults_going > 1 ? 'adults' : 'adult'
      }`
    }

    if (children_going) {
      childrenSizeText = `${children_going} ${
        children_going > 1 ? 'children' : 'child'
      }`
    }

    return adultsSizeText + separator + childrenSizeText
  }

  sheepCount = () => {
    return this.organizer_participating
      ? this.peopleGoingCount() - 1
      : this.peopleGoingCount()
  }

  uninvitedAdultsCount = () => {
    const enthusiastsWithAccount = this?.enthusiasts_parties?.length ?? 0
    const enthusiastsWithoutAccount =
      this.getInvitedEnthusiasts({ isGuardian: false })?.length ?? 0
    const totalInvitedAdults =
      enthusiastsWithAccount + enthusiastsWithoutAccount
    // Remove the organizer included in the enthusiast_parties list unless
    // they are participating
    const participating = this.organizer_participating == false ? 1 : 0

    return this.adultsGoingCount() - (totalInvitedAdults - participating)
  }

  uninvitedChildrenCount = () => {
    const guardiansWithoutAccount =
      this.getInvitedEnthusiasts({ isGuardian: true })?.length ?? 0
    const invitedChildrenCount = this?.children?.length ?? 0
    const totalInvitedChildren = invitedChildrenCount + guardiansWithoutAccount
    return this.childrenGoingCount() - totalInvitedChildren
  }

  areAllSheepInvited = () => {
    return this.uninvitedAdultsCount() + this.uninvitedChildrenCount() === 0
  }

  getEnthusiasts = () =>
    this?.enthusiasts_parties?.map(
      enthusiastsParty =>
        new EnthusiastPresenter(defined(enthusiastsParty?.enthusiast))
    )

  getSheep = () =>
    this?.enthusiasts_parties
      ?.filter(ep => !ep.organizer)
      .map(
        enthusiastsParty =>
          new EnthusiastPresenter(defined(enthusiastsParty?.enthusiast))
      )

  getChildren = () => this?.children?.map(child => new ChildPresenter(child))

  getInvitedEnthusiasts = ({ isGuardian }: { isGuardian?: boolean }) =>
    this?.invited_enthusiasts
      ?.filter(invited_enthusiasts => {
        if (isGuardian === undefined) return true
        const hasInvitedChild = invited_enthusiasts?.invited_child
        if (isGuardian) return hasInvitedChild
        return !hasInvitedChild
      })
      .map(
        invited_enthusiasts =>
          new InvitedEnthusiastPresenter(defined(invited_enthusiasts))
      )

  getInvitedChildren = () =>
    this?.invited_enthusiasts
      ?.filter(invited_enthusiasts => invited_enthusiasts?.invited_child)
      .map(
        invited_guardian =>
          new InvitedChildPresenter(
            definedNotNull(invited_guardian.invited_child)
          )
      )

  allDisclaimersAgreed = () => {
    const disclaimers_parties = defined(this.disclaimers_parties)
    if (disclaimers_parties.length === 0) return true

    const agreed = disclaimers_parties.filter(dp => {
      return definedNotNull(dp.disclaimer).agreed
    })
    return agreed.length >= defined(this.people_going)
  }
}
