import { HttpLink, DefaultOptions } from '@apollo/client'
import { ON_SERVER } from 'shared/enums/environment'
import { onError } from '@apollo/client/link/error'
import logOnDev from 'shared/helpers/logOnDev'
import { HttpHeader, XForceRole } from 'shared/enums/httpHeader'
import { getApolloURI } from 'helpers/apollo'

/**
 * ApolloProvider might not log an error message if the onError function is missing.
 *
 * The onError is baked in with ApolloLink so we don't have to add it every time a API call is created.
 * */
export const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (graphQLErrors) graphQLErrors.map(error => logOnDev(error))
  if (networkError) logOnDev(networkError)
})

export const httpLink = () =>
  new HttpLink({
    uri: getApolloURI(),
    credentials: 'include',
    headers: { [HttpHeader.xForceRole]: XForceRole.public }
  })

export const defaultOptions: DefaultOptions = {
  // Used by client.query
  query: {
    fetchPolicy: ON_SERVER ? 'no-cache' : 'network-only'
  },
  // Used by useQuery
  watchQuery: {
    fetchPolicy: ON_SERVER ? 'no-cache' : 'cache-and-network'
  }
}
