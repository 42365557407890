import * as Sentry from '@sentry/nextjs'
import {
  PREFIX_IMAGE_PATH,
  Transformation,
  TransformationKeys
} from 'shared/enums/image'

/**
 * These helpers provide methods of generating and retrieving specific
 * peices of data from Cloudinary URLs.
 *
 * The general structure of a Cloudinary URL contains:
 * https://res.cloudinary.com/<cloud_name>/<asset_type>/<delivery_type>/<transformations>/<version>/<public_id_path>.<extension>
 *
 * More information on the structure of Cloudinary URLs can be found here:
 * https://cloudinary.com/documentation/transformation_reference#overview
 */

/**
 * Returns a full Cloudinary image URL including host and transformation, ready
 * to be used to render an image.
 */
type GenerateCloudinaryUrl = (
  /**
   * The path of the Cloudinary image, without the host or
   * any transformations. May include version and file extension.
   */
  publicIdPath: string,
  /**
   * The pre-defined transform to apply
   */
  transform?: TransformationKeys
) => string

const generateCloudinaryUrl: GenerateCloudinaryUrl = (
  publicIdPath,
  transform = 'thumb'
) => {
  /**
   * If a full URL is already provided, return it.
   */
  if (['http', 'blob'].some(word => publicIdPath.startsWith(word)))
    return publicIdPath

  /*
   * This is to ensure backward compatibility with the
   * legacy business app.
   */
  const publicId = publicIdPath.includes('image/upload/')
    ? publicIdPath.split('image/upload/')[1]
    : publicIdPath

  return `${PREFIX_IMAGE_PATH}/${Transformation[transform]}/${publicId}`
}

/**
 * Returns a path to the image, with the asset and delivery type present,
 * and the host and transformation not present e.g.
 * image/upload/<version>/<public_id_path>
 *
 * This is the path we send to the eola API.
 */
type GenerateAssetUrl = (
  /**
   * The full Cloundary URL to the image (not including transform)
   */
  cloudinaryUrl: string
) => string
const generateAssetUrl: GenerateAssetUrl = cloudinaryUrl => {
  if (!cloudinaryUrl.includes('image/upload/')) {
    throw new Error(`Failed to format URL for upload: ${cloudinaryUrl}`)
  }

  return `image/upload/${cloudinaryUrl.split('image/upload/')[1]}`
}

/**
 * Returns the publicIdPath of the provided URL, with the asset and delivery type removed.
 */
type PublicIdPath = (
  /**
   * A path to the image, with the asset type and delivery type present.
   * The host and transformation may or may not be present e.g.
   * image/upload/<version>/<public_id_path>
   * or
   * https://res.cloundinary.com/eola/image/upload/<version>/<public_id_path>
   */
  url: string
) => string

const getPublicIdPath: PublicIdPath = url => {
  if (!url.includes('image/upload/')) {
    Sentry.captureException(`Unexpected asset URL provided: ${url}`)
    return url
  }

  return url.split('image/upload/')[1]
}

export { generateCloudinaryUrl, generateAssetUrl, getPublicIdPath }
