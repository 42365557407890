import { useRouter } from 'next/router'
import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useCallback,
  ReactNode
} from 'react'
import { ActiveModalProps } from 'shared/library/molecules/Modal/Modal'
import useStateRef from 'shared/state/useStateRef'
import { Maybe } from 'graphql/jsutils/Maybe'
import { trackViewSwitch } from 'shared/helpers/pageTrackerTools'

type NextHistoryState = {
  url: string
  as: string
  /**
   * idx isn't a publicly talked about property on the History API so nextjs
   * inherently does not expect it.
   * Making optional to make typescript happy (even though we expect it to be there)
   */
  idx?: number
}

export type ViewType = {
  id: string
  order: number
  channel: string[]
}

/**
 * used primarily for configuring the ViewSwitch via props
 */
export type Views = {
  [a: string]: ViewType
}

/**
 * ViewSwitch requires a queue mechanism for animation purposes
 */
export type QueueType = {
  next?: Maybe<string>
  curr: string
  prev?: Maybe<string>
}

export type ViewState = QueueType & {
  allViews?: Views
}

/**
 * Holds state on what views are rendered, the upcoming and previous views (for animations) and
 * the 'active' view (since there can be multiple views on the same page
 */
interface ViewsState {
  viewSwitchIds: {
    [viewSwitchId: string]: ViewState
  }
  activeViewSwitchId: string
}

export type PageRouterValues = PropsWithChildren<{
  activeModalId: string | null
  setActiveModalId: ActiveModalProps['setActiveModalId']
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getView: (viewSwitchId: string) => ViewState
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setView: (desiredView: string, viewSwitchId: string, setNow?: boolean) => void
  initViews: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    views: Views,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    viewSwitchId: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    initialViewId?: string
  ) => void
}>

export const PageRouterContext = createContext<PageRouterValues>({
  activeModalId: null,
  setActiveModalId: () => {
    throw new Error('usePageRouter must be used within PageRouterProvider')
  },
  getView: () => {
    throw new Error('usePageRouter must be used within PageRouterProvider')
  },
  setView: () => {
    throw new Error('usePageRouter must be used within PageRouterProvider')
  },
  initViews: () => {
    throw new Error('usePageRouter must be used within PageRouterProvider')
  }
})

const DEFAULT_VIEWS = {
  viewSwitchIds: {},
  activeViewSwitchId: ''
}

export const usePageRouterProvider = () => {
  const { setState: setSavedIdx, stateRef: lastSavedIdxRef } = useStateRef<
    string | null
  >('null')

  const {
    state: activeModalId,
    setState: setActiveModalId,
    stateRef: activeModalIdRef
  } = useStateRef<string | null>(null)

  const { setState: setViews, stateRef: viewsRef } =
    useStateRef<ViewsState>(DEFAULT_VIEWS)

  const { asPath, beforePopState, events } = useRouter()

  /**
   * When changing pages store the history state idx
   * This is used for identifying whether the user is moving forwards or backwards when using browser navigation
   */
  useEffect(() => {
    const handleRouteChange = () => {
      setSavedIdx(history?.state?.idx)
    }
    events.on('routeChangeComplete', handleRouteChange)
    // TODO #6362 - Fix ESLint - hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getViewSwitch = useCallback(
    (switchId: string) => {
      return viewsRef.current.viewSwitchIds[switchId]
    },
    [viewsRef]
  )

  const setView = useCallback(
    (
      desiredView: string,
      viewSwitchId: string,
      /**
       * ViewSwitch.tsx gives further explanation to this but in summary this prop allows us to determine
       * whether we should see another view change immediately after the one being fired in this function call
       * The reason we double on view changes at all is to allow the DOM to render a view in prep for another view's
       * exit animation. We do not set a .next in this case if we have already rendered the new view in prep and we can
       * just allow the animation to finish
       */
      isFinalViewChange = false
    ) => {
      const viewSwitch = getViewSwitch(viewSwitchId)

      const newQueue: ViewState = {
        allViews: viewSwitch.allViews,
        curr: ''
      }

      if (isFinalViewChange) {
        trackViewSwitch(viewSwitchId, desiredView)

        newQueue.curr = desiredView
        newQueue.prev = viewSwitch.curr
      } else {
        newQueue.next = desiredView
        newQueue.curr = viewSwitch?.curr ? viewSwitch.curr : desiredView
      }

      const newViews = {
        viewSwitchIds: {
          ...viewsRef.current.viewSwitchIds,
          [viewSwitchId]: newQueue
        },
        activeViewSwitchId: viewSwitchId
      }

      setViews(newViews)
    },
    [getViewSwitch, setViews, viewsRef]
  )

  /**
   * Initialise the state used for holding information about what views are rendered for a particular view switch
   *
   * This is an alternative to pulling all the view configs down via an enum into this provider upfront.
   * However that would be limiting - as might in some cases in the future desire to change the view config on the fly via stat - for example -
   * changing what view should come next based on user action (by changing the order prop)
   */
  const initViews = (
    allViews: Views,
    viewSwitchId: string,
    initialViewId?: string
  ) => {
    const newViews = { ...viewsRef.current }
    newViews.viewSwitchIds[viewSwitchId] = determineInitialView(
      initialViewId,
      allViews
    )

    newViews.activeViewSwitchId = viewSwitchId
    setViews(newViews)
  }

  /**
   * popstate event is fired for both forward and backwards browser navigation
   * annoyingly, there is no clear property that indicates which direction you are going
   * What we do have though is utilise the `idx` which indicates the position you are in in the history
   * we compare the previously visited page's idx to the current to determine which direction we are
   * moving
   */
  const determineBrowserDirection = useCallback(
    (newIdx = history?.state?.idx) => {
      if (
        typeof lastSavedIdxRef.current === 'undefined' ||
        lastSavedIdxRef.current === null ||
        newIdx === 0 ||
        newIdx < lastSavedIdxRef.current
      ) {
        return 'back'
      }

      if (newIdx > lastSavedIdxRef.current) {
        return 'forward'
      }

      return null
    },
    [lastSavedIdxRef]
  )

  const popstate = useCallback(
    (event: NextHistoryState) => {
      /**
       * early return if going forwards - we do not want to make changes to forwards behaviour currently
       * as it's not going to have a large impact on UX for it's cost
       */

      if (determineBrowserDirection(event?.idx) !== 'back') {
        return true
      }

      if (activeModalIdRef.current) {
        setActiveModalId(null)

        /**
         * You cannot prevent the back button firing from pop state....
         * so instead what we are doing is telling the history api to move forwards
         * this means that at the end of this function call the browser will
         * go back and immediately go forwards,
         * This happens so quickly that the page will not re-render (hacky
         * but the best of various hacky solutions at the time of writing)
         */
        history.go(1)
        return false
      }

      const activeViewSwitch = getViewSwitch(
        getActiveViewSwitchId(viewsRef.current)
      )
      if (!activeViewSwitch) {
        return true
      }
      const previousViewId = getPrecedingViewId(viewsRef.current)

      /** Go back to the previous view if available */
      if (activeViewSwitch?.curr && typeof previousViewId === 'string') {
        setView(previousViewId, getActiveViewSwitchId(viewsRef.current))
        history.go(1)
        return false
      }

      return true
    },
    [
      activeModalIdRef,
      determineBrowserDirection,
      getViewSwitch,
      setActiveModalId,
      setView,
      viewsRef
    ]
  )

  /**
   * Setup an event listener for browser triggered backwards and forwards navigation
   * issue with the alternative approach to this using nextjs routing - https://github.com/vercel/next.js/issues/6784
   * */
  useEffect(() => {
    beforePopState(popstate)

    return () => {
      if (window) {
        window.onbeforeunload = null
      }
      beforePopState(() => {
        return true
      })
    }
  }, [beforePopState, popstate])

  /** Reset all page level elements after a core nextjs page change */
  useEffect(() => {
    setActiveModalId(null)
  }, [asPath, setActiveModalId])

  return {
    activeModalId,
    setActiveModalId,
    getView: getViewSwitch,
    setView,
    initViews
  }
}

/**
 * We have implemented a shallow form of routing on top of individual nextjs pages
 *
 * The main purpose of this provider is to consolidate the logic around closing these layered UI elements (namely modals and views)
 * we want to intercept the browser's native back button to trigger the closing of these UI elements for improved ux.
 */
const PageRouterProvider = ({
  children
}: PropsWithChildren<{ children: ReactNode }>) => {
  const { activeModalId, setActiveModalId, setView, getView, initViews } =
    usePageRouterProvider()
  return (
    <PageRouterContext.Provider
      value={{
        activeModalId,
        setActiveModalId,
        setView,
        initViews,
        getView
      }}
    >
      {children}
    </PageRouterContext.Provider>
  )
}

export default PageRouterProvider

const sortViewsByOrder = (views: Views) => {
  return Object.keys(views).sort((viewIdA: string, viewIdB: string) => {
    return views[viewIdA].order - views[viewIdB].order
  })
}

const getActiveViewSwitchId = (views: ViewsState) => {
  return views.activeViewSwitchId
}

export const getPrecedingViewId = (views: ViewsState) => {
  const view = views.viewSwitchIds[getActiveViewSwitchId(views)]

  const allViews = view.allViews

  const currentViewId = view.curr

  if (!allViews) {
    return null
  }

  const currentViewOrder = allViews[currentViewId]?.order

  if (currentViewOrder - 1 < 0) {
    return null
  }

  const viewsForChannel = Object.values(allViews).filter(viewToCheck => {
    return allViews[currentViewId].channel.filter((channelId: string) =>
      viewToCheck.channel.includes(channelId)
    ).length
  })

  return viewsForChannel.find(view => view.order === currentViewOrder - 1)?.id
}

export const determineInitialView = (
  initialViewId: string | undefined,
  allViews: Views
) => {
  return {
    curr:
      !!initialViewId && !!allViews[initialViewId]
        ? initialViewId
        : sortViewsByOrder(allViews)[0],
    allViews: allViews,
    next: null
  }
}
